import React, { useState } from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/user/forgot-password-token", {
        email,
      });

      setEmailSent(true);
      toast.success("Password reset email sent");
      setEmail("");
    } catch (error) {
      console.error(error);
      toast.error("Failed to send password reset email");
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Avatar
            sx={{ m: 1, bgcolor: "#25D366" }}
            style={{ marginTop: "12%" }}
          >
            <WhatsAppIcon />
          </Avatar>
        </Box>

        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            padding: "30px",
          }}
        >
          <Typography component="h1" variant="h6" style={{ color: "#1976d2" }}>
            Forgot Password?
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            style={{ color: "gray", fontSize: "14px" }}
          >
            reset password
          </Typography>

          {emailSent ? (
            <p
              style={{
                color: "green",
                textAlign: "center",
                background: "lightgreen",
                borderRadius: "5px",
                padding: "20px",
              }}
            >
              Password reset email sent. Check your email for the reset link.
            </p>
          ) : (
            ""
          )}

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send
            </Button>

            <Grid container style={{ textAlign: "center" }}>
              <Grid item xs>
                <Link
                  href="/"
                  variant="body2"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Wait, I remember my password...
                  <span style={{ color: "#1976d2" }}> Back to Login</span>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ForgotPassword;
