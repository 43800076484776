import React, { useState, useEffect } from "react";
import { NavLink, useParams, Routes, Route } from "react-router-dom";
import GeneralDetailsPage from "./GeneralDetailsPage";
import TemplatesPage from "./TemplatesPage";
import axios from "axios";

const WhatsappCloudDetails = () => {
  const { id } = useParams();
  const [generalDetails, setGeneralDetails] = useState({});

  useEffect(() => {
    const fetchGeneralDetails = async () => {
      try {
        const response = await axios.get(`/api/whatsappcloud/get/${id}`); // Adjust the endpoint according to your backend API
        setGeneralDetails(response.data);
      } catch (error) {
        console.error("Error fetching general details:", error);
      }
    };

    fetchGeneralDetails();
  }, [id]);

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">WhatsApp Cloud Details</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div className="status_main">
              <div className="card status_table">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <NavLink
                        to={`/dashboard/whatsappcloud/${id}/generaldetails`}
                        className="nav-link"
                        activeClassName="active"
                      >
                        General Details
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to={`/dashboard/whatsappcloud/${id}/templates`}
                        className="nav-link"
                        activeClassName="active"
                      >
                        Templates
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Routes>
                    <Route
                      path="/generaldetails"
                      element={
                        <GeneralDetailsPage generalDetails={generalDetails} />
                      }
                    />
                    <Route
                      path="/templates"
                      element={<TemplatesPage id={id} />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsappCloudDetails;
