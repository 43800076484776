import React from "react";

const GeneralDetailsVone = ({ generalDetails }) => {
  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">General Details</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div className="status_main">
              <div className="status_submain status_submain_new">
                <div className="card status_profile">
                  <div className="card-body" style={{ overflow: "auto" }}>
                    <h5>Primary Contact</h5>
                    <div style={{ color: "#979797" }}>
                      <div className="user_details">
                        <span>
                          <i className="ri-user-line"></i>
                        </span>
                        <p>Connection Name : {generalDetails.connectionname}</p>
                      </div>
                      <div className="user_details">
                        <span>
                          <i className="ri-phone-line"></i>
                        </span>
                        <p>API URL : {generalDetails.apiurl}</p>
                      </div>
                      <div className="user_details">
                        <span>
                          <i className="ri-phone-line"></i>
                        </span>
                        <p>API Key : {generalDetails.apikey}</p>
                      </div>
                      <div className="user_details">
                        <span>
                          <i className="ri-phone-line"></i>
                        </span>
                        <p>CRM API Key : {generalDetails.crmapikey}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralDetailsVone;
