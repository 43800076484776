import React from "react";

const Emails = () => {
  return (
    <div>
      <h5 className="card-title">This place is silent. Too silent.</h5>
      <p className="card-text">
        Talking to your customers is a great way to get them started on the path
        to conversion. Go ahead..
      </p>
      <a href="/" className="btn btn-primary">
        New Email
      </a>
    </div>
  );
};

export default Emails;
