import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table } from "antd";
import { Box, TextField } from "@mui/material";

const AllCourses = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editCoursesId, setEditCoursesId] = useState(null);

  const [courses, setCourses] = useState({
    coursename: "",
  });

  const fetchCoursesData = async () => {
    try {
      const response = await axios.get("/api/allcourses/getcourses");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCoursesData();
  }, []);

  const handleChangeCourses = (e) => {
    const { name, value } = e.target;
    setCourses({
      ...courses,
      [name]: value,
    });
  };

  const handleAddCourses = (e) => {
    e.preventDefault();

    const coursesData = {
      ...courses,
    };

    if (editCoursesId) {
      axios
        .put(`/api/allcourses/edit/${editCoursesId}`, coursesData)
        .then((res) => {
          setCourses({ coursename: "" });
          Swal.fire({
            title: "Courses Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchCoursesData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Courses. Please try again.",
            "error"
          );
        });
    } else {
      axios
        .post("/api/allcourses/create", coursesData)
        .then((res) => {
          setCourses({ coursename: "" });
          Swal.fire({
            title: "Courses Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchCoursesData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Courses. Please try again.",
            "error"
          );
        });
    }
  };

  const handleDeleteCourses = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/allcourses/delete/${_id}`)
        .then((res) => {
          fetchCoursesData();
          Swal.fire("Deleted!", "Courses deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleEditCourses = (_id) => {
    const selectedCourses = data.find((tagid) => tagid._id === _id);

    if (selectedCourses) {
      setEditCoursesId(_id); // Set the ID of the contact being edited
      setCourses({
        coursename: selectedCourses.coursename,
      }); // Populate the form fields with the selected contact data
    }
    setIsModalOpen(true);
  };

  const handleAddCoursesButton = () => {
    setEditCoursesId(null);
    setCourses({ coursename: "" });
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },

    {
      title: "COURSES",
      dataIndex: "coursename",
      key: "coursename",
      ellipsis: true,
    },
    {
      title: "Unique ID",
      dataIndex: "_id",
      key: "_id",
      ellipsis: true,
    },

    {
      title: "ACTION",
      key: "action",
      width: 150,

      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditCourses(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>
          <button
            onClick={() => handleDeleteCourses(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">All Courses</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    {/* <!-- Button trigger modal --> */}
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleAddCoursesButton}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add
                      Courses
                    </button>

                    {/* <!-- Modal --> */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                      style={{ display: isModalOpen ? "block" : "none" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editCoursesId ? "Update Courses" : "Add Courses"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              <TextField
                                color="primary"
                                fullWidth
                                label="Course Name"
                                name="coursename"
                                value={courses.coursename}
                                onChange={handleChangeCourses}
                                margin="normal"
                                required
                                autoFocus
                              />
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              onClick={handleAddCourses}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {editCoursesId ? "Update" : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }} // Show scrollbar only if data is lengthy
                  pagination={{
                    pageSize: 5,
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCourses;
