import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, TextField } from "@mui/material";
import {
  MinusCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Space, Table, Tag, Button, Select, Spin } from "antd";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import "../styles/webinarleads.css";

const WebinarLeads = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [total, setTotal] = useState(0);

  const [editContactId, setEditContactId] = useState(null);

  // Add Custom field
  const [customsData, setCustomsData] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [nextCustomFieldType, setNextCustomFieldType] = useState("");

  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [contact, setContact] = useState({
    name: "",
    email: "",
    whatsappMobile: "",
    mobile: "",
    personalisedattachment: "",
    tag: "",
  });

  const [useSameNumber, setUseSameNumber] = useState(false);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  // Custom loading spinner using Ant Design's LoadingOutlined icon
  const customLoadingIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#1890ff" }} spin />
  );

  const handleEditContact = (_id) => {
    let selectedContact;

    // Check if the contact exists in searchedData
    if (searchedData.length > 0) {
      selectedContact = searchedData.find((contact) => contact._id === _id);
    } else {
      // If not found in searchedData, fall back to data
      selectedContact = data.find((contact) => contact._id === _id);
    }

    if (selectedContact) {
      setEditContactId(_id);
      setContact({
        name: selectedContact.name,
        email: selectedContact.email,
        whatsappMobile: selectedContact.whatsappMobile,
        mobile: selectedContact.mobile,
        personalisedattachment: selectedContact.personalisedattachment,
      });
      setSelectedTags(selectedContact.tag);
      setCustomFields(
        selectedContact.customFields.map((field) => ({
          title: field.title,
          value: field.value,
        }))
      );
    }
  };

  const fetchContactData = async () => {
    try {
      const params = {
        startreq:
          (tableParams.pagination.current - 1) *
          tableParams.pagination.pageSize,
        limit: tableParams.pagination.pageSize,
      };

      const response = await axios.get(
        `/api/webinarleads/getleads?${qs.stringify(params)}`
      );
      setData(response.data.contacts);
      setTotal(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };

  const fetchSearchData = async () => {
    if (searchQuery.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.get("/api/webinarleads/search", {
          params: { query: searchQuery },
        });
        setSearchedData(response.data.contacts);
      } catch (error) {
        console.error("Error fetching search data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchedData([]);
    }
  };

  useEffect(() => {
    fetchSearchData();
  }, [searchQuery]);

  // Fetch Contact data on component mount
  useEffect(() => {
    fetchContactData();
    // fetchTemplateData();
    fetchCustomsData();
  }, [JSON.stringify(tableParams)]);

  const handleChangeContact = (e) => {
    const { name, value } = e.target;
    setContact((prevContact) => {
      const updatedContact = {
        ...prevContact,
        [name]: value,
      };

      if (useSameNumber && name === "mobile") {
        updatedContact.whatsappMobile = value;
      }
      return updatedContact;
    });
  };

  const handleCheckboxChange = () => {
    setUseSameNumber((prevValue) => !prevValue);
  };

  const handleChangeTags = (selectedTags) => {
    setSelectedTags(selectedTags);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleSubmitLeads = async (e) => {
    e.preventDefault();
    // Check for errors before submitting
    try {
      setLoading(true);
      const formData = {
        ...contact,
        flag: "webinarleads",
        customFields: customFields.filter((field) => field.value.trim() !== ""),
        tag: selectedTags,
      };

      // Check if the checkbox is checked
      if (useSameNumber) {
        // Use the WhatsApp number for mobile if the checkbox is checked
        formData.mobile = formData.whatsappMobile;
      }

      if (editContactId) {
        await axios
          .put(`/api/webinarleads/update/${editContactId}`, formData)
          .then((res) => {
            setContact({
              name: "",
              email: "",
              whatsappMobile: "",
              mobile: "",
              personalisedattachment: "",
            });
            setCustomFields([]);
            setSelectedTags([]);
            Swal.fire({
              title: "Contact Updated successfully!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              fetchContactData();
              fetchSearchData();
            });
          })
          .catch((error) => {
            let errorMessage = "Error creating Contact. Please try again.";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMessage = error.response.data.message;
            }
            Swal.fire("Error", errorMessage, "error");
            console.log(error);
          });
      } else {
        await axios
          .post("/api/webinarleads/add", formData)
          .then((response) => {
            setContact({
              name: "",
              email: "",
              whatsappMobile: "",
              mobile: "",
              personalisedattachment: "",
              tag: "",
              customFields: "",
            });
            setCustomFields([]);
            setSelectedTags([]);
            Swal.fire({
              title: "Contact Added successfully!",
              icon: "success",

              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              fetchContactData();
            });
          })
          .catch((error) => {
            let errorMessage = "Error creating Contact. Please try again.";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMessage = error.response.data.message;
            }
            Swal.fire("Error", errorMessage, "error");
            Swal.fire("Error", errorMessage, "error");
            console.log(error);
          });
      }
    } catch (error) {
      console.error("Error submitting contact data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitTags = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const formData = {
        tags: selectedTags.map((tag) => ({ title: tag })),
      };

      await axios
        .post("/api/webinarleads/updatealltags", formData)
        .then((response) => {
          setContact({
            tag: "",
          });
          setCustomFields([]);
          setSelectedTags([]);
          Swal.fire({
            title: "Tags Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchContactData();
            fetchSearchData();
          });
        })
        .catch((error) => {
          Swal.fire("Error", "Error updating tags. Please try again.", "error");
        });
    } catch (error) {
      console.error("Error submitting contact data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  // Add Custom Fields Starts

  const handleAddCustomField = () => {
    const selectedCustom = customsData.find(
      (custom) => custom.title === nextCustomFieldType
    );

    if (selectedCustom) {
      setCustomFields([
        ...customFields,
        { title: selectedCustom.title, value: "" },
      ]);
      setNextCustomFieldType("");
    }
  };

  const handleCustomFieldValueChange = (index, event) => {
    const updatedFields = [...customFields];
    updatedFields[index].value = event.target.value;
    setCustomFields(updatedFields);
  };

  const handleRemoveCustomField = (index) => {
    const updatedFields = [...customFields];
    updatedFields.splice(index, 1);
    setCustomFields(updatedFields);
  };

  const handleDeleteContact = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // Delete contact with the given ID
      axios
        .delete(`/api/webinarleads/delete/${_id}`)
        .then((res) => {
          Swal.fire("Deleted!", "Contact deleted successfully!", "success");
          fetchContactData();
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  // const filteredCustomFields = customsData.filter(
  //   (custom) => custom.showintable === true
  // );

  // const customFieldColumn = filteredCustomFields.map((custom) => ({
  //   title: custom.title,
  //   dataIndex: custom.title, // Assuming title is a unique identifier for each custom field
  //   key: custom.title,
  // }));

  function generateTagColor(tag) {
    const hash = tag
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    const hue = hash % 360; // Use the hash value to determine the hue
    return `hsl(${hue}, 70%, 50%)`; // Generate an HSL color based on the hue
  }

  // ######################################### Flag Update ###########################################

  const [flagOptions, setFlagOptions] = useState("");

  // Function to handle flag option change for a specific record
  const handleFlagOptionChange = (e, _id) => {
    const selectedOption = e.target.value;
    setFlagOptions((prevOptions) => ({
      ...prevOptions,
      [_id]: selectedOption,
    }));
  };

  // Function to update flag in the backend using Axios
  const updateFlagInBackend = async (_id, selectedOption) => {
    try {
      const response = await axios.put(`api/webinarleads/flag/${_id}`, {
        flag: selectedOption,
      });

      if (response.status !== 200) {
        throw new Error("Failed to update flag");
      }

      // Optionally handle success feedback
      // console.log("Flag updated successfully");
      fetchContactData();
    } catch (error) {
      console.error("Error updating flag:", error);
      // Optionally handle error feedback
      alert("Failed to update flag. Please try again.");
    }
  };

  // useEffect to trigger backend update when flagOption changes for any record
  useEffect(() => {
    // Loop through each record's flag option and update backend
    Object.entries(flagOptions).forEach(([id, option]) => {
      updateFlagInBackend(id, option);
    });
  }, [flagOptions]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getTagsData = async () => {
      try {
        const response = await axios.get("/api/webinarleads/tags/gettags");
        const responseData = response.data;

        const getTagsName = responseData.map((tag) => tag.title);

        setOptions(getTagsName);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getTagsData();
  }, []);

  const handleAddContactButtonClick = () => {
    setEditContactId(null);

    setContact({
      name: "",
      email: "",
      whatsappMobile: "",
      mobile: "",
      personalisedattachment: "",
    });
    setCustomFields([]);
    setSelectedTags([]);
  };

  const [customTitle, setCustomTitle] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customFieldResponse = await axios.get(
          "/api/webinarleads/customfield"
        );

        const extractedTitles = customFieldResponse.data.map(
          (custom) => custom.title
        );
        setCustomTitle(extractedTitles);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {}, [filteredData]);

  // Function to determine the data source based on filters and search

  const getDataSource = () => {
    if (filteredData.length > 0) {
      // Display filtered data if filters are applied
      return filteredData;
    } else if (searchQuery.trim() !== "") {
      // Display searched contacts if search query is present
      // return searchContacts();
      return searchedData;
    } else {
      // Display all contacts by default
      return data;
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  // Select Show Headers
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const fetchCustomsData = async () => {
    try {
      const response = await axios.get("/api/webinarleads/customfield");
      setCustomsData(response.data);
      setCustomFields(response.data);
    } catch (error) {
      console.error("Error fetching customs data:", error);
    }
  };

  // =========================================================================================
  // Handle checkbox toggle
  const handleCheckboxToggle = async (field) => {
    const updatedShowInTable = !field.showintable; // Toggle the current value of showintable
    const updatedField = { ...field, showintable: updatedShowInTable }; // Create an updated field object

    // Update checkbox state locally
    setCustomFields((prevFields) =>
      prevFields.map((f) => (f._id === field._id ? updatedField : f))
    );

    // Send the updated showintable value to the backend
    try {
      await axios.patch(`/api/webinarleads/showcustomfield/${field._id}`, {
        showintable: updatedShowInTable,
      });
      fetchCustomsData();
    } catch (error) {
      console.error("Error updating custom field:", error);
    }
  };

  // =========================================================================================

  const columns = [
    //Actions
    {
      title: "Action",
      fixed: "left",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            className="edit-btn"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            onClick={() => handleEditContact(record._id)}
          >
            <i className="ri-eye-line"></i>
          </button>

          <button
            onClick={() => handleDeleteContact(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
    //Name
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },

    // ...customFieldColumn,
    //Email
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },
    //Whatsapp Number
    {
      title: "WhatsApp Number",
      dataIndex: "whatsappMobile",
      key: "whatsappMobile",
      ellipsis: true,
    },

    //Flag
    {
      title: "Flag",
      dataIndex: "flag",
      key: "flag",
      ellipsis: true,
      render: (text, record) => (
        <select
          className="form-select"
          value={flagOptions[record._id] || record.flag}
          onChange={(e) => handleFlagOptionChange(e, record._id)}
        >
          <option value="webinarleads" disabled>
            Webinar Leads
          </option>
          <option value="customer">Customer</option>
          <option value="leads">Leads</option>
        </select>
      ),
    },

    //Tags
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      ellipsis: true,

      render: (tags = []) => (
        <div>
          {tags.slice(0, 2).map((tag, index) => (
            <Tag
              key={index}
              style={{
                backgroundColor: generateTagColor(tag),
                color: "#fff",
                marginBottom: "8px",
              }}
            >
              {tag}
            </Tag>
          ))}
          {tags.length > 2 && <span> +{tags.length - 2} more</span>}
        </div>
      ),
    },

    // Render dynamic columns for selected custom fields
    ...customFields
      .filter((field) => field.showintable) // Only include fields with showintable = true
      .map((field) => ({
        title: field.title,
        dataIndex: field.title,
        key: field.title,
        render: (text, record) => {
          const customField = record.customFields.find(
            (customField) => customField.title === field.title
          );
          return customField ? customField.value : "-"; // If custom field exists, show its value, otherwise show '-'
        },
      })),
  ];

  // Search Custom Field in Selection List
  const [searchOption, setSearchOption] = useState("");

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchOption(event.target.value);
  };

  // Filter custom fields based on the search query
  const filteredCustomFields = customFields.filter((field) =>
    field.title.toLowerCase().includes(searchOption.toLowerCase())
  );

  return (
    <div>
      <div className="page-content w-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 py-3">Webinar Leads</h4>
                <div>
                  <h4 className="mb-sm-0 py-3">Total Contacts : {total}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <div id="contactList" className="card">
                <div className="card-header py-3">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="d-flex flex-wrap flex-grow-1 gap-2">
                      <button
                        type="button"
                        className="btn btn-primary add-btn"
                        onClick={handleAddContactButtonClick}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> Add
                        WebinarLeads
                      </button>
                      <form
                        className="d-flex"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <input
                          className="form-control me-2"
                          type="search"
                          id="search"
                          placeholder="Search"
                          aria-label="Search"
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                      </form>

                      {/* Add Contacts Modal */}
                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Add Webinar Leads
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <form onSubmit={handleSubmitLeads}>
                                <div>
                                  <TextField
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    label="Enter Name"
                                    name="name"
                                    value={contact.name}
                                    onChange={handleChangeContact}
                                    margin="normal"
                                    type="text"
                                    required
                                    autoFocus
                                  />
                                </div>
                                <div>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    label="Enter Email"
                                    name="email"
                                    value={contact.email}
                                    onChange={handleChangeContact}
                                    margin="normal"
                                    type="email"
                                    required
                                  />
                                </div>
                                <Grid
                                  container
                                  spacing={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={6}>
                                    <div>
                                      <PhoneInput
                                        inputClass="phone-input-className w-100"
                                        value={contact.whatsappMobile}
                                        onChange={(value, data, event) => {
                                          if (event && event.target) {
                                            handleChangeContact(event);
                                          }
                                        }}
                                        inputProps={{
                                          name: "whatsappMobile",
                                          required: true,
                                          placeholder: "Enter Whatsapp Number",
                                        }}
                                        dropdownClass="custom-dropdown-className"
                                        required
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div>
                                      <PhoneInput
                                        inputClass="phone-input-className w-100"
                                        value={
                                          useSameNumber
                                            ? contact.whatsappMobile
                                            : contact.mobile
                                        }
                                        onChange={(value, data, event) => {
                                          if (event && event.target) {
                                            handleChangeContact(event);
                                          }
                                        }}
                                        inputProps={{
                                          name: "mobile",
                                          placeholder: "Enter Mobile Number",
                                          required: true,
                                        }}
                                        dropdownClass="custom-dropdown-className"
                                        required
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    <label
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={useSameNumber}
                                        onChange={handleCheckboxChange}
                                      />
                                      <span>Use the same number for Calls</span>
                                    </label>
                                  </Grid>
                                </Grid>

                                <div>
                                  <TextField
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    label="Personalised Attachement (Optional)"
                                    name="personalisedattachment"
                                    value={contact.personalisedattachment}
                                    onChange={handleChangeContact}
                                    autoComplete="personalisedattachment"
                                    autoFocus
                                  />
                                </div>
                                <div>
                                  <Multiselect
                                    isObject={false}
                                    placeholder="Tags"
                                    onSelect={(selectedList) =>
                                      handleChangeTags(selectedList)
                                    }
                                    onRemove={(selectedList) =>
                                      handleChangeTags(selectedList)
                                    }
                                    options={options}
                                    selectedValues={selectedTags} // Pass selected tags to the Multiselect component
                                    showCheckbox
                                  />
                                </div>
                                <div>
                                  {customFields.map((field, index) => (
                                    <div key={index}>
                                      <Grid
                                        container
                                        spacing={1}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Grid item xs={11}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            label={`${field.title}`}
                                            type="text"
                                            margin="normal"
                                            value={field.value}
                                            onChange={(e) =>
                                              handleCustomFieldValueChange(
                                                index,
                                                e
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <MinusCircleOutlined
                                            style={{
                                              fontSize: "25px",
                                              textAlign: "center",
                                            }}
                                            onClick={() =>
                                              handleRemoveCustomField(index)
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}

                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "10px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Grid item xs={9}>
                                      <select
                                        className="form-select"
                                        margin="normal"
                                        value={nextCustomFieldType}
                                        onChange={(e) =>
                                          setNextCustomFieldType(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select a Custom Field
                                        </option>
                                        {customsData.map((custom) => (
                                          <option
                                            key={custom._id}
                                            value={custom.title}
                                          >
                                            {custom.title}
                                          </option>
                                        ))}
                                      </select>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Button
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        type="dashed"
                                        onClick={handleAddCustomField}
                                        icon={<PlusOutlined />}
                                      >
                                        Add field
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </div>

                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    data-bs-dismiss="modal"
                                    className="btn btn-primary"
                                  >
                                    Add Webinar Leads
                                  </button>
                                </div>
                              </form>
                            </div>
                            {loading && (
                              <div className="loading-indicator">
                                Loading...
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* ************************************************************* */}
                      {/* Update Tags Modal */}
                      <div
                        className="modal fade"
                        id="updateModal"
                        tabIndex="-1"
                        aria-labelledby="updateModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="updateModalLabel">
                                Update Tags
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <form onSubmit={handleSubmitTags}>
                                <div>
                                  <Multiselect
                                    isObject={false}
                                    placeholder="Tags"
                                    onSelect={(selectedList) =>
                                      handleChangeTags(selectedList)
                                    }
                                    onRemove={(selectedList) =>
                                      handleChangeTags(selectedList)
                                    }
                                    options={options}
                                    selectedValues={selectedTags} // Pass selected tags to the Multiselect component
                                    showCheckbox
                                  />
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    data-bs-dismiss="modal"
                                    className="btn btn-primary"
                                  >
                                    Update Tags
                                  </button>
                                </div>
                              </form>
                            </div>
                            {loading && (
                              <div className="loading-indicator">
                                Loading...
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ==================================================================================== */}
                    {/* Select Show Headers */}

                    {/* <div className="multiselect-container">
                      <div
                        className="multiselect-header"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        <span>
                          {customFields.length === 0
                            ? "Select Custom Fields"
                            : `${
                                customFields.filter((f) => f.showintable).length
                              } field(s) selected`}
                        </span>
                        <span className="dropdown-arrow">&#9662;</span>
                      </div>
                      {isDropdownOpen && (
                        <div className="multiselect-options">
                          {customFields.map((field) => (
                            <label
                              key={field._id}
                              className="multiselect-option"
                            >
                              <input
                                type="checkbox"
                                checked={field.showintable}
                                onChange={() => handleCheckboxToggle(field)} // Handle toggle
                              />
                              {field.title}
                            </label>
                          ))}
                        </div>
                      )}
                    </div> */}

                    <div className="multiselect-container">
                      <div
                        className="multiselect-header"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        <span>
                          {customFields.length === 0
                            ? "Select Custom Fields"
                            : `${
                                customFields.filter((f) => f.showintable).length
                              } field(s) selected`}
                        </span>
                        <span className="dropdown-arrow">&#9662;</span>
                      </div>
                      {isDropdownOpen && (
                        <div className="multiselect-options">
                          <input
                            type="text"
                            placeholder="Search fields..."
                            value={searchOption}
                            onChange={handleSearchChange}
                            className="search-bar"
                          />

                          {filteredCustomFields.map((field) => (
                            <label
                              key={field._id}
                              className="multiselect-option"
                            >
                              <input
                                type="checkbox"
                                checked={field.showintable}
                                onChange={() => handleCheckboxToggle(field)}
                              />
                              {field.title}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>

                    {/* Select Show Headers Ends */}
                    <div className="flex-shrink-0">
                      {/* View & Edit Modal */}
                      <div
                        className="sidemodal slide-from-right modal fade"
                        id="myModal"
                        tabIndex="-1"
                      >
                        <div className="side-modal-dialog modal-dialog modal-dialog-scrollable">
                          <div className="side-modal-content modal-content">
                            <div className="modal-header">
                              <h4 className="modal-title">{contact.name}</h4>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                              ></button>
                            </div>
                            <form onSubmit={handleSubmitLeads}>
                              <div className="modal-body scrollable-modal-body">
                                <section className="qualification section">
                                  <div className="section__title">
                                    <img
                                      width={85}
                                      height={85}
                                      style={{ borderRadius: "50%" }}
                                      src="https://confidentialcontent.s3.eu-west-1.wasabisys.com/wateam/kz734vks.png"
                                      alt=""
                                    />
                                  </div>
                                  {/* <span className="section__subtitle">Name</span> */}
                                  <div className="qualification__container container">
                                    <div className="qualification__tabs">
                                      <div
                                        className={
                                          toggleState === 1
                                            ? "qualification__button qualification__active button-flex"
                                            : "qualification__button button-flex"
                                        }
                                        onClick={() => toggleTab(1)}
                                      >
                                        <h6>General Details</h6>
                                      </div>
                                      <div
                                        className={
                                          toggleState === 2
                                            ? "qualification__button qualification__active button-flex"
                                            : "qualification__button button-flex"
                                        }
                                        onClick={() => toggleTab(2)}
                                      >
                                        <h6>Custom Details</h6>
                                      </div>
                                    </div>

                                    <div className="qualification__sections">
                                      <div
                                        className={
                                          toggleState === 1
                                            ? "qualification__content qualification__content-active"
                                            : "qualification__content"
                                        }
                                      >
                                        <div className="qualification__data">
                                          <div>
                                            <TextField
                                              color="primary"
                                              size="small"
                                              fullWidth
                                              label="Enter Name"
                                              name="name"
                                              value={contact.name}
                                              onChange={handleChangeContact}
                                              margin="normal"
                                              type="text"
                                              required
                                              autoFocus
                                            />
                                          </div>
                                          <div>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              label="Enter Email"
                                              name="email"
                                              value={contact.email}
                                              onChange={handleChangeContact}
                                              margin="normal"
                                              type="email"
                                              required
                                            />
                                          </div>
                                          <div>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              margin="normal"
                                              label="Whatsapp Number"
                                              name="whatsappMobile"
                                              value={contact.whatsappMobile}
                                              onChange={handleChangeContact}
                                              type="tel"
                                              required
                                              autoFocus
                                            />
                                          </div>
                                          <div>
                                            <TextField
                                              size="small"
                                              margin="normal"
                                              required
                                              fullWidth
                                              label="Phone"
                                              name="mobile"
                                              value={contact.mobile}
                                              onChange={handleChangeContact}
                                              type="tel"
                                              inputProps={{
                                                maxLength: 12,
                                              }}
                                              autoComplete="mobile"
                                              autoFocus
                                            />
                                          </div>
                                          <div>
                                            <TextField
                                              size="small"
                                              margin="normal"
                                              fullWidth
                                              label="Personalised Attachement"
                                              name="personalisedattachment"
                                              value={
                                                contact.personalisedattachment
                                              }
                                              onChange={handleChangeContact}
                                              autoComplete="personalisedattachment"
                                              autoFocus
                                            />
                                          </div>
                                          <div className="mb-5">
                                            <div>
                                              <Multiselect
                                                label="Tags"
                                                isObject={false}
                                                onSelect={(selectedList) =>
                                                  handleChangeTags(selectedList)
                                                }
                                                onRemove={(selectedList) =>
                                                  handleChangeTags(selectedList)
                                                }
                                                options={options}
                                                selectedValues={selectedTags} // Pass selected tags to the Multiselect component
                                                showCheckbox
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className={
                                          toggleState === 2
                                            ? "qualification__content qualification__content-active"
                                            : "qualification__content"
                                        }
                                      >
                                        <div className="qualification__data">
                                          <div>
                                            {customFields.map(
                                              (field, index) => (
                                                <div key={index}>
                                                  <Grid
                                                    container
                                                    spacing={1}
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <Grid item xs={11}>
                                                      <TextField
                                                        size="small"
                                                        fullWidth
                                                        label={`${field.title}`}
                                                        type="text"
                                                        margin="normal"
                                                        value={field.value}
                                                        onChange={(e) =>
                                                          handleCustomFieldValueChange(
                                                            index,
                                                            e
                                                          )
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                      <MinusCircleOutlined
                                                        style={{
                                                          fontSize: "25px",
                                                          textAlign: "center",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveCustomField(
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </div>
                                              )
                                            )}

                                            <Grid
                                              container
                                              spacing={1}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginTop: "10px",
                                                marginBottom: "20px",
                                              }}
                                            >
                                              <Grid item xs={9}>
                                                <select
                                                  className="form-select"
                                                  margin="normal"
                                                  value={nextCustomFieldType}
                                                  onChange={(e) =>
                                                    setNextCustomFieldType(
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Select a Custom Field
                                                  </option>
                                                  {customsData.map((custom) => (
                                                    <option
                                                      key={custom._id}
                                                      value={custom.title}
                                                    >
                                                      {custom.title}
                                                    </option>
                                                  ))}
                                                </select>
                                              </Grid>
                                              <Grid item xs={2}>
                                                <Button
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                  type="dashed"
                                                  onClick={handleAddCustomField}
                                                  icon={<PlusOutlined />}
                                                >
                                                  Add field
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>

                              {/*<!-- Modal footer --> */}
                              <div className="modal-footer fixed-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  data-bs-dismiss="modal"
                                  className="btn btn-primary"
                                >
                                  {editContactId ? "Update Leads" : "Add Leads"}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2 align-item-center"></div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <Table
                    dataSource={getDataSource()}
                    columns={columns}
                    rowKey="_id"
                    rowSelection={rowSelection}
                    loading={{
                      spinning: loading,
                      indicator: <Spin indicator={customLoadingIcon} />,
                    }}
                    pagination={{
                      ...tableParams.pagination,
                      total,
                      position: "bottomRight",
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "15", "20", "50", "100"],
                    }}
                    onChange={handleTableChange}
                    bordered
                    components={{
                      header: {
                        cell: ({ style, ...restProps }) => (
                          <th
                            {...restProps}
                            style={{
                              ...style,
                              backgroundColor: "#c6c6c6",
                              color: "black",
                              textAlign: "center",
                            }}
                          />
                        ),
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarLeads;
