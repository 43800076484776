import React from "react";

const ErrorPage = () => {
  return (
    <>
      <div class="error" id="error">
        <div class="container">
          <div class="content centered">
            <img style="width:500px;" src="/assets/images/something-lost.png" />
            <h1>Oops, looks like the page is lost.</h1>
            <p style="font-size:22px;" class="sub-header text-block-narrow">
              This is not a fault, just an accident that was not intentional.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
