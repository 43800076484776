import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table } from "antd";
import { Box, TextField, Grid, Button } from "@mui/material";
import { Switch } from "antd";

const Team = () => {
  const [data, setData] = useState([]);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get("/api/user/getalluser");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleClearTeamForm = () => {
    setUser({
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      password: "",
    });
  };

  const handleDeleteUser = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/user/deleteauser/${_id}`)
        .then((res) => {
          fetchUserData();
          Swal.fire("Deleted!", "User deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const register = (e) => {
    e.preventDefault();
    const { firstname, lastname, email, mobile, password } = user;

    if (firstname && lastname && email && mobile && password) {
      axios
        .get(`/api/user/check-email/${email}`)
        .then((response) => {
          if (response.data.exists) {
            // Display an error message indicating that the email is already in use
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "User with this email is already exists.",
            });
          } else {
            // Continue with user registration if email is not in use
            axios
              .post("/api/user/register", {
                ...user,
              })
              .then((res) => {
                Swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: "Teammate Added successfully!",
                });
                fetchUserData();
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
              });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong while checking email availability.",
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all the fields.",
      });
    }
  };

  const handleBlockUser = async (_id) => {
    try {
      await axios.put(`/api/user/block-user/${_id}`);
      fetchUserData();
      Swal.fire("Blocked!", "User blocked successfully!", "success");
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const handleUnblockUser = async (_id) => {
    try {
      await axios.put(`/api/user/unblock-user/${_id}`);
      fetchUserData();
      Swal.fire("Unblocked!", "User unblocked successfully!", "success");
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },

    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (text, record) => `${record.firstname} ${record.lastname}`,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },
    {
      title: "ROLE",
      dataIndex: "role",
      key: "role",
      ellipsis: true,
    },

    {
      title: "ACTION",
      key: "action",
      width: 150,

      render: (text, record) => (
        <Space size="middle">
          <Switch
            checkedChildren="Block"
            unCheckedChildren="Unblock"
            defaultChecked={record.isBlocked}
            onChange={(checked) =>
              checked
                ? handleBlockUser(record._id)
                : handleUnblockUser(record._id)
            }
          />

          <button
            onClick={() => handleDeleteUser(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Team</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    {/* <!-- Button trigger modal --> */}
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleClearTeamForm}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add Team
                    </button>

                    {/* <!-- Modal --> */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Team
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  <TextField
                                    margin="normal"
                                    required
                                    size="small"
                                    fullWidth
                                    id="firstname"
                                    label="First Name"
                                    name="firstname"
                                    value={user.firstname}
                                    onChange={handleChange}
                                    autoComplete="firstname"
                                    autoFocus
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    margin="normal"
                                    size="small"
                                    required
                                    fullWidth
                                    id="lastname"
                                    label="Last Name"
                                    name="lastname"
                                    value={user.lastname}
                                    onChange={handleChange}
                                    autoComplete="lastname"
                                    autoFocus
                                  />
                                </Grid>
                              </Grid>

                              <TextField
                                margin="normal"
                                size="small"
                                required
                                fullWidth
                                id="mobile"
                                label="Mobile"
                                name="mobile"
                                value={user.mobile}
                                onChange={handleChange}
                                autoComplete="mobile"
                                autoFocus
                              />
                              <TextField
                                margin="normal"
                                size="small"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                value={user.email}
                                onChange={handleChange}
                                autoComplete="email"
                                autoFocus
                              />
                              <TextField
                                margin="normal"
                                size="small"
                                required
                                fullWidth
                                name="password"
                                value={user.password}
                                onChange={handleChange}
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                              />
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <Button
                              onClick={register}
                              fullWidth
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }} // Show scrollbar only if data is lengthy
                  pagination={{
                    pageSize: 5,
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
