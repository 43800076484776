import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table } from "antd";
import { Box, TextField } from "@mui/material";
import { Link } from "react-router-dom";
const WaTeamVersion1 = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editWaTeamVersionOneId, setEditWaTeamVersionOneId] = useState(null);

  const [wateamversionone, setWaTeamVersionOne] = useState({
    connectionname: "",
    apiurl: "",
    apikey: "",
    crmapikey: "",
  });

  const fetchWaTeamVersionOneData = async () => {
    try {
      const response = await axios.get("/api/whatsappv1/getall");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWaTeamVersionOneData();
  }, []);

  const handleChangeWaTeamVersionOne = (e) => {
    const { name, value } = e.target;
    setWaTeamVersionOne({
      ...wateamversionone,
      [name]: value,
    });
  };

  const handleAddWaTeamVersionOne = (e) => {
    e.preventDefault();

    const wateamversiononeData = {
      ...wateamversionone,
    };
    if (editWaTeamVersionOneId) {
      axios
        .put(
          `/api/whatsappv1/update/${editWaTeamVersionOneId}`,
          wateamversiononeData
        )
        .then((res) => {
          setWaTeamVersionOne({
            connectionname: "",
            apiurl: "",
            apikey: "",
            crmapikey: "",
          });
          Swal.fire({
            title: "WaTeam V1 Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchWaTeamVersionOneData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating WaTeam V1. Please try again.",
            "error"
          );
        });
    } else {
      axios
        .post("/api/whatsappv1/create", wateamversiononeData)
        .then((res) => {
          setWaTeamVersionOne({
            connectionname: "",
            apiurl: "",
            apikey: "",
            crmapikey: "",
          });
          Swal.fire({
            title: "WaTeam V1 Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchWaTeamVersionOneData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating WaTeam V1. Please try again.",
            "error"
          );
        });
    }
  };

  const handleDeleteWaTeamVersionOne = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/whatsappv1/delete/${_id}`)
        .then((res) => {
          fetchWaTeamVersionOneData();
          Swal.fire("Deleted!", "WaTeam V1 deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleEditWaTeamVersionOne = (_id) => {
    const selectedWaTeamVersionOne = data.find((item) => item._id === _id);

    if (selectedWaTeamVersionOne) {
      setEditWaTeamVersionOneId(_id); // Set the ID of the contact being edited
      setWaTeamVersionOne({
        connectionname: selectedWaTeamVersionOne.connectionname,
        apiurl: selectedWaTeamVersionOne.apiurl,
        apikey: selectedWaTeamVersionOne.apikey,
        crmapikey: selectedWaTeamVersionOne.crmapikey,
      }); // Populate the form fields with the selected contact data
    }
    setIsModalOpen(true);
  };

  const handleAddWaTeamVersionOneButton = () => {
    setEditWaTeamVersionOneId(null);
    setWaTeamVersionOne({
      connectionname: "",
      apiurl: "",
      apikey: "",
      crmapikey: "",
    });
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },

    {
      title: "Connection Name",
      dataIndex: "connectionname",
      key: "connectionname",
      ellipsis: true,
    },
    {
      title: "API URL",
      dataIndex: "apiurl",
      key: "apiurl",
      ellipsis: true,
    },
    {
      title: "API KEY",
      dataIndex: "apikey",
      key: "apikey",
      ellipsis: true,
    },
    {
      title: "CRM API Key",
      dataIndex: "crmapikey",
      key: "crmapikey",
      ellipsis: true,
    },

    {
      title: "ACTION",
      key: "action",
      width: 150,

      render: (text, record) => (
        <Space size="middle">
          <Link
            to={{
              pathname: `/dashboard/wateamvone/${record._id}/generaldetailsvone`,
              state: { entry: record }, // Pass the entire entry object as state
            }}
          >
            <button type="button" className="edit-btn">
              <i className="ri-eye-line"></i>
            </button>
          </Link>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditWaTeamVersionOne(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>
          <button
            onClick={() => handleDeleteWaTeamVersionOne(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Wa-Team Version1</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    {/* <!-- Button trigger modal --> */}
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleAddWaTeamVersionOneButton}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i>
                      Wa-Team V1
                    </button>

                    {/* <!-- Modal --> */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                      // style={{ display: isModalOpen ? "block" : "none" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editWaTeamVersionOneId
                                ? "Update Wa-Team V1"
                                : "Add Wa-Team V1"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              <TextField
                                color="primary"
                                fullWidth
                                label="Connection Name"
                                name="connectionname"
                                value={wateamversionone.connectionname}
                                onChange={handleChangeWaTeamVersionOne}
                                margin="normal"
                                required
                                autoFocus
                              />
                              <TextField
                                color="primary"
                                fullWidth
                                label="API URL"
                                name="apiurl"
                                value={wateamversionone.apiurl}
                                onChange={handleChangeWaTeamVersionOne}
                                margin="normal"
                                required
                                autoFocus
                              />
                              <TextField
                                color="primary"
                                fullWidth
                                label="API KEY"
                                name="apikey"
                                value={wateamversionone.apikey}
                                onChange={handleChangeWaTeamVersionOne}
                                margin="normal"
                                required
                                autoFocus
                              />
                              <TextField
                                color="primary"
                                fullWidth
                                label="CRM API KEY"
                                name="crmapikey"
                                value={wateamversionone.crmapikey}
                                onChange={handleChangeWaTeamVersionOne}
                                margin="normal"
                                required
                                autoFocus
                              />
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              onClick={handleAddWaTeamVersionOne}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {editWaTeamVersionOneId ? "Update" : "Ok"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="_id"
                  // scroll={{ x: true }}
                  // style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }} // Show scrollbar only if data is lengthy
                  pagination={{
                    pageSize: 5,
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaTeamVersion1;
