import React, { useState, useEffect } from "react";
import {
  NavLink,
  useParams,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import GeneralDetailsVtwo from "./GeneralDetailsVtwo";
import TemplatesVtwo from "./TemplatesVtwo";

const WaTeamVersion2Details = () => {
  const { id } = useParams();
  const [generalDetails, setGeneralDetails] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const fetchGeneralDetails = async () => {
      try {
        const response = await axios.get(`/api/whatsappv2/get/${id}`); // Adjust the endpoint according to your backend API
        setGeneralDetails(response.data);
      } catch (error) {
        console.error("Error fetching general details:", error);
      }
    };

    fetchGeneralDetails();
  }, [id]);

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Wa-Team Version2 Details</h4>
              <button
                className="btn btn-secondary"
                onClick={() => navigate(-1)} // Navigate back to the previous page
              >
                Back
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div className="status_main">
              <div className="card status_table">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <NavLink
                        to={`/dashboard/wateamvtwo/${id}/generaldetailsvtwo`}
                        className="nav-link"
                        activeClassName="active"
                      >
                        General Details
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to={`/dashboard/wateamvtwo/${id}/templatesvtwo`}
                        className="nav-link"
                        activeClassName="active"
                      >
                        Templates
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Routes>
                    <Route
                      path="/generaldetailsvtwo"
                      element={
                        <GeneralDetailsVtwo generalDetails={generalDetails} />
                      }
                    />
                    <Route
                      path="/templatesvtwo"
                      element={<TemplatesVtwo id={id} />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaTeamVersion2Details;
