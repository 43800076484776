import "./App.css";
import MainLayout from "./components/MainLayout";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Resetpassword from "./pages/Resetpassword";
import Forgotpassword from "./pages/Forgotpassword";
import Customfields from "./pages/Customfields";
import Contacts from "./pages/Contacts";
import Profile from "./pages/Profile";
import ReportsTemp from "./pages/ReportsTemp";
import ReportsContact from "./pages/ReportsContact";
import ApiDocumentation from "./pages/ApiDocumentation";
import TemplateSender from "./pages/TemplateSender";
import TemplateWaTeam from "./pages/TemplateWaTeam";
import Schedule from "./pages/Schedule";
import AwsDashboard from "./pages/AwsDashboard";
import SignUp from "./pages/SignUp";
import Tags from "./pages/Tags";
import Testing from "./pages/Testing";
import Authorization from "./pages/Authorization";
import Segments from "./pages/Segments";
import ApiKey from "./pages/ApiKey";
import Protected from "./components/Protected";
import TwoFactorAuth from "./pages/TwoFactorAuth";
import Timeline from "./pages/Timeline";
import Activities from "./pages/Activities";
import Notes from "./pages/Notes";
import Invoices from "./pages/Invoices";
import Status from "./pages/Status";
import Courses from "./pages/Courses";
import DTMF_Campaign from "./pages/DTMF_Campaign";
import Whatsapp_Campaign from "./pages/Whatsapp_Campaign";
import Payment from "./pages/Payment";
import Emails from "./pages/Emails";
import Personalinfo from "./pages/Personalinfo";
import AllCourses from "./pages/AllCourses";
import Team from "./pages/Team";
import { useState, useEffect } from "react";
import ErrorPage from "./pages/ErrorPage";
import CurrentWebinar from "./pages/CurrentWebinar";
import FutureWebinar from "./pages/FutureWebinar";
import ExpiredWebinar from "./pages/ExpiredWebinar";
import Leads from "./pages/Leads";
import WhatsappCloud from "./pages/WhatsappCloud";
import WhatsappCloudDetails from "./pages/WhatsappCloudDetails";
import WaTeamVersion1 from "./pages/WaTeamVersion1";
import WaTeamVersion2 from "./pages/WaTeamVersion2";
import WaTeamRedLava from "./pages/WaTeamRedLava";
import WaTeamAws from "./pages/WaTeamAws";
import Reports from "./pages/Reports";
import GeneralDetailsPage from "./pages/GeneralDetailsPage";
import TemplatesPage from "./pages/TemplatesPage";
import WaTeamVersion1Details from "./pages/WaTeamVersion1Details";
import GeneralDetailsVone from "./pages/GeneralDetailsVone";
import TemplatesVone from "./pages/TemplatesVone";
import WaTeamVersion2Details from "./pages/WaTeamVersion2Details";
import GeneralDetailsVtwo from "./pages/GeneralDetailsVtwo";
import TemplatesVtwo from "./pages/TemplatesVtwo";
import MessageStatus from "./pages/MessageStatus";
import BulkCampaign from "./pages/BulkCampaign";
import NewCampaign from "./pages/NewCampaign";
import SuppressionList from "./pages/SuppressionList";
import WebinarAutomation from "./pages/WebinarAutomation";
import EmailsWebinarAutomation from "./pages/EmailsWebinarAutomation";
import IvrCalls from "./pages/IvrCalls";
import CustomFieldKeys from "./pages/CustomFieldKeys";
import WebinarTag from "./pages/WebinarTag";
import Webhook from "./pages/Webhook";
import WebinarLeads from "./pages/WebinarLeads";

function App() {
  const [role, setRole] = useState(""); // Add a state variable to store user role

  useEffect(() => {
    // Retrieve the user's role from local storage
    const storedRole = localStorage.getItem("role");
    if (storedRole) {
      setRole(storedRole);
    }
  }, []);

  // Function to check if the user has admin role
  const isAdmin = () => {
    return role === "admin";
  };
  // console.log(isAdmin());

  return (
    <Router>
      <Routes>
        {/* <Route path="*" element={<h1>404 Page Not Found</h1>} /> */}
        {/* <Route path="/*" element={<ErrorPage />} /> */}
        <Route path="/" element={<Login />} />
        <Route path="/two-factor-authentication" element={<TwoFactorAuth />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password/:token" element={<Resetpassword />} />
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/dashboard" element={<Protected Component={MainLayout} />}>
          <Route path="*" element={<ErrorPage />} />
          <Route index element={<Protected Component={Dashboard} />} />

          <Route path="contact" element={<Protected Component={Contacts} />} />
          <Route path="leads" element={<Protected Component={Leads} />} />
          <Route
            path="webinarleads"
            element={<Protected Component={WebinarLeads} />}
          />
          <Route
            path="dtmf_campaign"
            element={<Protected Component={DTMF_Campaign} />}
          />
          <Route
            path="whatsapp_campaign"
            element={<Protected Component={Whatsapp_Campaign} />}
          />
          <Route
            path="bulkcampaign"
            element={<Protected Component={BulkCampaign} />}
          />
          <Route
            path="new-campaign"
            element={<Protected Component={NewCampaign} />}
          />

          <Route
            path="status/:contactId"
            element={<Protected Component={Status} />}
          >
            <Route
              path="timeline"
              element={<Protected Component={Timeline} />}
            />
            <Route
              path="activities"
              element={<Protected Component={Activities} />}
            />
            <Route path="courses" element={<Protected Component={Courses} />} />
            <Route path="payment" element={<Protected Component={Payment} />} />
            <Route path="emails" element={<Protected Component={Emails} />} />
            <Route path="notes" element={<Protected Component={Notes} />} />
            <Route
              path="messagestatus"
              element={<Protected Component={MessageStatus} />}
            />
            <Route
              path="personalinformation"
              element={<Protected Component={Personalinfo} />}
            />
            <Route
              path="invoices"
              element={<Protected Component={Invoices} />}
            />
          </Route>
          <Route
            path="customfields"
            element={<Protected Component={Customfields} />}
          />
          <Route
            path="customfieldkeys"
            element={<Protected Component={CustomFieldKeys} />}
          />
          <Route
            path="allcourses"
            element={<Protected Component={AllCourses} />}
          />
          {/* AutoWapSender Starts*/}
          <Route
            path="awsdashboard"
            element={<Protected Component={AwsDashboard} />}
          />
          <Route
            path="messagetemplate"
            element={<Protected Component={TemplateSender} />}
          />
          <Route
            path="reportstemplate"
            element={<Protected Component={ReportsTemp} />}
          />
          {/* AutoWapSender Ends */}
          <Route
            path="wamessagetemplate"
            element={<Protected Component={TemplateWaTeam} />}
          />

          <Route path="profile" element={<Protected Component={Profile} />} />

          <Route
            path="reportswateam"
            element={<Protected Component={ReportsContact} />}
          />
          <Route
            path="apidocumentation"
            element={<Protected Component={ApiDocumentation} />}
          />
          <Route path="schedule" element={<Protected Component={Schedule} />} />
          <Route path="segments" element={<Protected Component={Segments} />} />
          <Route
            path="suppressionlist"
            element={<Protected Component={SuppressionList} />}
          />
          <Route path="tags" element={<Protected Component={Tags} />} />
          <Route path="testing" element={<Protected Component={Testing} />} />
          <Route
            path="authorization"
            element={<Protected Component={Authorization} />}
          />
          <Route path="apikey" element={<Protected Component={ApiKey} />} />
          <Route path="webhook" element={<Protected Component={Webhook} />} />
          <Route
            path="currentwebinar"
            element={<Protected Component={CurrentWebinar} />}
          />
          <Route
            path="futurewebinar"
            element={<Protected Component={FutureWebinar} />}
          />
          <Route
            path="webinartag"
            element={<Protected Component={WebinarTag} />}
          />
          <Route
            path="expirewebinar"
            element={<Protected Component={ExpiredWebinar} />}
          />
          <Route
            path="webinar_automation"
            element={<Protected Component={WebinarAutomation} />}
          />
          <Route
            path="emails_webinar_automation"
            element={<Protected Component={EmailsWebinarAutomation} />}
          />
          <Route
            path="ivr_calls"
            element={<Protected Component={IvrCalls} />}
          />

          {/* <Route path="team" element={<Protected Component={Team} />} /> */}
          <Route
            path="team"
            element={<Protected Component={Team} requiredRole="admin" />}
          />
          <Route path="whatsappcloud">
            <Route index element={<WhatsappCloud />} />
            <Route path=":id" element={<WhatsappCloudDetails />}>
              <Route path="generaldetails" element={<GeneralDetailsPage />} />
              <Route path="templates" element={<TemplatesPage />} />
            </Route>
          </Route>
          <Route path="wateamvone">
            <Route index element={<WaTeamVersion1 />} />
            <Route path=":id" element={<WaTeamVersion1Details />}>
              <Route
                path="generaldetailsvone"
                element={<GeneralDetailsVone />}
              />
              <Route path="templatesvone" element={<TemplatesVone />} />
            </Route>
          </Route>
          {/* <Route
            path="wateamvtwo"
            element={<Protected Component={WaTeamVersion2} />}
          /> */}
          <Route path="wateamvtwo">
            <Route index element={<WaTeamVersion2 />} />
            <Route path=":id" element={<WaTeamVersion2Details />}>
              <Route
                path="generaldetailsvtwo"
                element={<GeneralDetailsVtwo />}
              />
              <Route path="templatesvtwo" element={<TemplatesVtwo />} />
            </Route>
          </Route>
          <Route
            path="waredlava"
            element={<Protected Component={WaTeamRedLava} />}
          />
          <Route path="waaws" element={<Protected Component={WaTeamAws} />} />
          <Route path="reports" element={<Protected Component={Reports} />} />
        </Route>
        {/* 404 Page */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
