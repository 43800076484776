import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Space,
  Table,
  Tag,
  Card,
  Row,
  Col,
  Typography,
  Timeline,
  Select,
} from "antd";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
const { Text, Title } = Typography;
const { Option } = Select;

const Reports = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const parseDateTime = (timestamp) => {
    if (timestamp)
      return new Date(Number(timestamp) * 1000).toLocaleString("sv");
    else return "————";
  };

  useEffect(() => {
    const fetchReportsData = async () => {
      try {
        const response = await axios.get(
          "https://reports.custwiz.com/api/wacloud/webhook/get"
        );
        const dataBase = response.data;

        const tabledata = dataBase.map((item) => {
          const datetime = item.createdAt.split("T");
          const date = datetime[0];
          const time = datetime[1]?.substring(0, 8);

          return {
            key: item._id,
            time: `${date} ${time}`,
            to:
              item.entry[0]?.changes[0]?.value?.statuses[0]?.recipient_id || "",
            type:
              item.entry[0]?.changes[0]?.value?.statuses[0]?.conversation
                ?.origin?.type || "",
            status:
              item.entry[0]?.changes[0]?.value?.statuses[0]?.status || "Failed",
            waId: item.entry[0]?.changes[0]?.value?.statuses[0]?.id || "",
            sysId: item._id,
          };
        });

        setData(tabledata);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchReportsData();
  }, []);

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },

    {
      title: "To",
      dataIndex: "to",
      key: "to",
      ellipsis: true,
    },
    {
      title: "Creation Time",
      dataIndex: "time",
      key: "time",
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
    },
    {
      title: "Last Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      render: (status) => {
        let color;
        let x = status.toLowerCase();
        switch (x) {
          case "delivered":
            color = "lime";
            break;
          case "read":
            color = "green";
            break;
          case "sent":
            color = "lime";
            break;
          case "failed":
            color = "red";
            break;
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Whatsapp Id",
      dataIndex: "waId",
      key: "waId",
      ellipsis: true,
    },
    {
      title: "System Id",
      dataIndex: "sysId",
      key: "sysId",
      ellipsis: true,
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Reports</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    {/* <!-- Button trigger modal --> */}
                    <h6>Reports</h6>
                  </div>
                  <div className="import-button">
                    {/* <button
                      type="button"
                      className="btn btn-primary add-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add
                      Reports
                    </button> */}
                    <Select
                      defaultValue="Select Option"
                      style={{ width: 200 }}
                      onChange={handleSelectChange}
                    >
                      <Option value="whatsappcloud">Whatsapp Cloud</Option>
                      <Option value="whatsappversionone">WaTeam V1</Option>
                      <Option value="whatsappversiontwo">WaTeam V2</Option>
                      <Option value="redlava">WaTeam RedLava</Option>
                      <Option value="whatsappaws">WaTeam AWS</Option>
                    </Select>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  loading={loading}
                  //   rowKey="_id"
                  pagination={{
                    pageSize: 5,
                  }}
                  // expandable={{
                  //   expandedRowRender: (record) => {
                  //     const card1Content = () => {
                  //       switch (record.status.toLowerCase()) {
                  //         case "read":
                  //           return (
                  //             <Timeline
                  //               mode="right"
                  //               items={[
                  //                 {
                  //                   children: "Accepted",
                  //                   dot: <SyncOutlined />,
                  //                   color: "black",
                  //                 },
                  //                 {
                  //                   children: "Sent",
                  //                   dot: <CheckCircleOutlined />,
                  //                   color: "blue",
                  //                 },
                  //                 {
                  //                   children: "Delivered",
                  //                   dot: <CheckCircleOutlined />,
                  //                   color: "orange",
                  //                 },
                  //                 {
                  //                   children: "Read",
                  //                   label: record.time,
                  //                   dot: <CheckCircleOutlined />,
                  //                   color: "green",
                  //                 },
                  //               ]}
                  //             />
                  //           );

                  //         case "delivered":
                  //           return (
                  //             <Timeline
                  //               mode="right"
                  //               items={[
                  //                 {
                  //                   children: "Accepted",
                  //                   dot: <SyncOutlined />,
                  //                   color: "black",
                  //                 },
                  //                 {
                  //                   children: "Sent",
                  //                   dot: <CheckCircleOutlined />,
                  //                 },
                  //                 {
                  //                   children: "Delivered",
                  //                   label: record.time,
                  //                   dot: <CheckCircleOutlined />,
                  //                   color: "lime",
                  //                 },
                  //               ]}
                  //             />
                  //           );

                  //         case "sent":
                  //           return (
                  //             <Timeline
                  //               mode="right"
                  //               items={[
                  //                 {
                  //                   children: "Accepted",
                  //                   dot: <SyncOutlined />,
                  //                   color: "black",
                  //                 },
                  //                 {
                  //                   children: "Sent",
                  //                   label: record.time,
                  //                   dot: <CheckCircleOutlined />,
                  //                 },
                  //               ]}
                  //             />
                  //           );

                  //         case "failed":
                  //           return (
                  //             <>
                  //               <Text>Error Code : </Text>
                  //               <Text>Error Message : </Text>
                  //               <Text>Error Details : </Text>
                  //             </>
                  //           );
                  //       }
                  //     };

                  //     return (
                  //       <Row>
                  //         <Col span="7" offset="2">
                  //           <Card hoverable>{card1Content()}</Card>
                  //         </Col>
                  //         <Col span="8" offset="2">
                  //           <Card title="preview Message" hoverable>
                  //             Testing Message
                  //           </Card>
                  //         </Col>
                  //       </Row>
                  //     );
                  //   },
                  // }}

                  expandable={{
                    expandedRowRender: (record) => {
                      const card1Content = () => {
                        if (record.status.toLowerCase() === "read") {
                          return (
                            <Timeline
                              mode="right"
                              items={[
                                {
                                  children: "Accepted",
                                  dot: <SyncOutlined />,
                                  color: "black",
                                  label: record.time,
                                },
                                {
                                  children: "Sent",
                                  label: parseDateTime(record.timeline.sent),
                                  dot: <CheckCircleOutlined />,
                                },
                                {
                                  children: "Delivered",
                                  label: parseDateTime(
                                    record.timeline.delivered
                                  ),
                                  dot: <CheckCircleOutlined />,
                                  color: "orange",
                                },
                                {
                                  children: "Read",
                                  label: parseDateTime(record.timeline.read),
                                  dot: <CheckCircleOutlined />,
                                  color: "green",
                                },
                              ]}
                            />
                          );
                        } else if (
                          record.status.toLowerCase() === "delivered"
                        ) {
                          return (
                            <Timeline
                              mode="right"
                              items={[
                                {
                                  children: "Accepted",
                                  dot: <SyncOutlined />,
                                  color: "black",
                                  label: record.time,
                                },
                                {
                                  children: "Sent",
                                  label: parseDateTime(record.timeline.sent),
                                  dot: <CheckCircleOutlined />,
                                },
                                {
                                  children: "Delivered",
                                  label: parseDateTime(
                                    record.timeline.delivered
                                  ),
                                  dot: <CheckCircleOutlined />,
                                  color: "orange",
                                },
                              ]}
                            />
                          );
                        } else if (record.status.toLowerCase() === "sent") {
                          return (
                            <Timeline
                              mode="right"
                              items={[
                                {
                                  children: "Accepted",
                                  dot: <SyncOutlined />,
                                  color: "black",
                                  label: record.time,
                                },
                                {
                                  children: "Sent",
                                  label: parseDateTime(record.timeline.sent),
                                  dot: <CheckCircleOutlined />,
                                },
                              ]}
                            />
                          );
                        } else if (record.status.toLowerCase() === "failed") {
                          return (
                            <>
                              <Text>Error Code: </Text>
                              <br />
                              <Text>Error Message: </Text>
                              <br />
                              <Text>Error Details: </Text>
                            </>
                          );
                        }
                      };

                      return (
                        <Row>
                          <Col span="7" offset="2">
                            <Card hoverable>{card1Content()}</Card>
                          </Col>
                          <Col span="8" offset="2">
                            <Card title="preview Message" hoverable>
                              {" "}
                            </Card>
                          </Col>
                        </Row>
                      );
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
