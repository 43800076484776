import React, { useState, useEffect } from "react";
import { Space, Table } from "antd";
import { Box, TextField } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const ApiKey = () => {
  const [data, setData] = useState([]);
  const [editApiId, setEditApiId] = useState(null);
  const [api, setApi] = useState({
    name: "",
    isactive: "",
  });

  const fetchApiKeyData = async () => {
    try {
      const response = await axios.get("/api/apikey/getapikey");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchApiKeyData();
  }, []);

  const handleChangeApiKeys = (e) => {
    const { name, value } = e.target;
    setApi({
      ...api,
      [name]: value,
    });
  };

  const handleAddApiKey = (e) => {
    e.preventDefault();

    const apiData = {
      ...api,
    };

    if (editApiId) {
      axios
        .put(`/api/apikey/editapikey/${editApiId}`, apiData)
        .then((res) => {
          setApi({ name: "", isactive: "" });
          Swal.fire({
            title: "ApiKey Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchApiKeyData();
          });
        })
        .catch((error) => {
          Swal.fire("Error", "Error creating Tags. Please try again.", "error");
        });
    } else {
      axios
        .post("/api/apikey/createapikey", apiData)
        .then((res) => {
          setApi({ name: "", isactive: "" });
          Swal.fire({
            title: "ApiKey Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchApiKeyData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating ApiKey. Please try again.",
            "error"
          );
        });
    }
  };

  const handleEditApiKey = (_id) => {
    const selectedApiKey = data.find((apikeyid) => apikeyid._id === _id);

    if (selectedApiKey) {
      setEditApiId(_id);
      setApi({
        name: selectedApiKey.name,
        isactive: selectedApiKey.isactive,
      });
    }
  };

  const handleDeleteApiKey = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // Delete contact with the given ID
      axios
        .delete(`/api/apikey/deleteapikey/${_id}`)
        .then((res) => {
          // toast.success("Custom Field deleted successfully!");
          fetchApiKeyData();
          Swal.fire("Deleted!", "Apikey deleted successfully!", "success");
        })
        .catch((error) => {
          // toast.error("Something went wrong!");
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const columns = [
    {
      title: "KEY",
      dataIndex: "key",
      key: "key",
      color: "blue",
      ellipsis: true,
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "ACTIVE",
      dataIndex: "isactive",
      key: "isactive",
      ellipsis: true,
      render: (isactive) => (
        <span style={{ color: isactive ? "green" : "red" }}>
          {isactive ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      title: "CREATED DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: true,
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: "UPDATED DATE",
      dataIndex: "updatedAt",
      key: "updatedAt",
      ellipsis: true,
      render: (updatedAt) => new Date(updatedAt).toLocaleString(),
    },
    {
      title: "ACTION",
      key: "action",
      width: 150,

      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditApiKey(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>

          <button
            onClick={() => handleDeleteApiKey(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">API Keys</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    {/* <!-- Button trigger modal --> */}
                    <h6>API Key List</h6>
                    {/* <!-- Modal --> */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              API Key
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              <TextField
                                color="primary"
                                size="small"
                                fullWidth
                                label="Name"
                                name="name"
                                value={api.name}
                                onChange={handleChangeApiKeys}
                                margin="normal"
                                required
                                autoFocus
                              />
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="isactive"
                                value={api.isactive}
                                onChange={handleChangeApiKeys}
                              >
                                <option selected value="" disabled>
                                  Active
                                </option>
                                <option value={true} name={true}>
                                  true
                                </option>
                                <option value={false} name={false}>
                                  false
                                </option>
                              </select>
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              onClick={handleAddApiKey}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="import-button">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Create
                      API Key
                    </button>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  // style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }} // Show scrollbar only if data is lengthy
                  pagination={{
                    pageSize: 5,
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiKey;
