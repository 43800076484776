import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table, Switch } from "antd";
import { Box, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const Webhook = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editWebhookId, setEditWebhookId] = useState(null);

  const [webhook, setWebhook] = useState({
    title: "",
    url: "",
    webhookevents: {
      currentWebinar: false,
      leads: false,
      customer: false,
      tagApplied: false,
    },
  });

  const fetchWebhookData = async () => {
    try {
      const response = await axios.get("/api/webhook/get");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(data);

  useEffect(() => {
    fetchWebhookData();
  }, []);

  const handleChangeWebhook = (e) => {
    const { name, value } = e.target;
    setWebhook({
      ...webhook,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setWebhook((prev) => ({
      ...prev,
      webhookevents: {
        ...prev.webhookevents,
        [name]: checked,
      },
    }));
  };

  const handleAddWebhook = (e) => {
    e.preventDefault();

    const webhookData = {
      ...webhook,
    };

    // Serialize the webhook events for the API
    webhookData.webhookevents = Object.keys(webhook.webhookevents).filter(
      (key) => webhook.webhookevents[key]
    );

    if (editWebhookId) {
      axios
        .put(`/api/webhook/update/${editWebhookId}`, webhookData)
        .then((res) => {
          setWebhook({ title: "", url: "", webhookevents: {} });
          Swal.fire({
            title: "Webhook Updated successfully!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1000,
          }).then(() => {
            fetchWebhookData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Webhook. Please try again.",
            "error"
          );
        });
    } else {
      axios
        .post("/api/webhook/create", webhookData)
        .then((res) => {
          setWebhook({ title: "", url: "", webhookevents: {} });
          Swal.fire({
            title: "Webhook Added successfully!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1000,
          }).then(() => {
            fetchWebhookData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Webhook. Please try again.",
            "error"
          );
        });
    }
  };

  const handleDeleteWebhook = async (_id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      axios
        .delete(`/api/webhook/delete/${_id}`)
        .then((res) => {
          fetchWebhookData();
          Swal.fire({
            title: "Deleted!",
            text: "Webhook deleted successfully!",
            icon: "success",
            timer: 1000, // Notification auto-closes after 2 seconds
            showConfirmButton: false, // Hide the OK button
          });
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleEditWebhook = (_id) => {
    const selectedWebhook = data.find((webhookid) => webhookid._id === _id);

    if (selectedWebhook) {
      setEditWebhookId(_id);
      setWebhook({
        title: selectedWebhook.title,
        url: selectedWebhook.url,
        webhookevents: {
          currentWebinar:
            selectedWebhook.webhookevents.includes("currentWebinar"),
          leads: selectedWebhook.webhookevents.includes("leads"),
          customer: selectedWebhook.webhookevents.includes("customer"),
          tagApplied: selectedWebhook.webhookevents.includes("tagApplied"),
        },
      });
    }
    setIsModalOpen(true);
  };

  const handleAddWebhookButton = () => {
    setEditWebhookId(null);
    setWebhook({ title: "", url: "", webhookevents: {} });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const searchContacts = () => {
    return data.filter((contact) => {
      return (
        contact.title &&
        contact.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  const getDataSource = () => {
    if (searchQuery) {
      return searchContacts();
    } else {
      return data;
    }
  };

  const handleOnWebhook = async (_id) => {
    try {
      await axios.put(`/api/webhook/onwebhook/${_id}`);
      fetchWebhookData();
      Swal.fire({
        title: "Webhook is ON Now!",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const handleOffWebhook = async (_id) => {
    try {
      await axios.put(`/api/webhook/offwebhook/${_id}`);
      fetchWebhookData();
      Swal.fire({
        title: "Webhook is OFF Now!",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (error) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
      align: "center",
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      align: "center",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      ellipsis: true,
      align: "center",
    },

    {
      title: "Webhook Event",
      dataIndex: "webhookevents",
      key: "webhookevents",
      align: "center",
      render: (events) =>
        events && events.length > 0 ? events.join(", ") : "-",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "isEnabled",
      key: "isEnabled",
      ellipsis: true,
      align: "center",
      render: (isEnabled) => (
        <span style={{ color: isEnabled ? "green" : "red" }}>
          {isEnabled ? "True" : "False"}
        </span>
      ),
    },
    {
      title: "ACTION",
      key: "action",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditWebhook(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>
          <button
            onClick={() => handleDeleteWebhook(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>

          {/* <Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked /> */}
          <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            defaultChecked={record.isEnabled}
            onChange={(checked) =>
              checked
                ? handleOnWebhook(record._id)
                : handleOffWebhook(record._id)
            }
          />
        </Space>
      ),
    },
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Webhook</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    {/* Webhook Modal */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                      style={{ display: isModalOpen ? "block" : "none" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editWebhookId ? "Update Webhook" : "Add Webhook"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              <h6>Title</h6>
                              <TextField
                                color="primary"
                                size="small"
                                fullWidth
                                label="Title"
                                name="title"
                                value={webhook.title}
                                onChange={handleChangeWebhook}
                              />
                              <h6 className="mt-3">URL</h6>
                              <TextField
                                color="primary"
                                size="small"
                                fullWidth
                                label="URL"
                                name="url"
                                value={webhook.url}
                                onChange={handleChangeWebhook}
                              />
                              <h6 className="mt-3">Webhook Events</h6>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="currentWebinar"
                                    checked={
                                      webhook.webhookevents.currentWebinar ||
                                      false
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                }
                                label="Current Webinar"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="leads"
                                    checked={
                                      webhook.webhookevents.leads || false
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                }
                                label="Leads"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="customer"
                                    checked={
                                      webhook.webhookevents.customer || false
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                }
                                label="Customer"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="tagApplied"
                                    checked={
                                      webhook.webhookevents.tagApplied || false
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                }
                                label="Tag Applied"
                              />
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              onClick={handleAddWebhook}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {editWebhookId ? "Update" : "Create"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleAddWebhookButton}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add
                      Webhook
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={getDataSource()}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }}
                  pagination={{
                    pageSize: 5,
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Webhook;
