import axios from "axios";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

function LoadingModal({ uploadProgress }) {
  return (
    <div
      className="loading-modal"
      style={{
        display: "flex",
        background: "rgba(0, 0, 0, 0.7)",
        backdropFilter: "blur(5px)",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1050,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className="spinner-border" role="status" style={{ color: "#fff" }}>
        <span className="visually-hidden">Loading...</span>
      </div>
      <div style={{ color: "#fff", marginTop: "20px" }}>
        Uploading: {uploadProgress}%
      </div>
    </div>
  );
}

function CsvHeadersModal({ csvHeaders, onClose, file, fetchContactData }) {
  const [customsData, setCustomsData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const selectedKeys = [
    "name",
    "email",
    "mobile",
    "whatsappMobile",
    "personalisedattachment",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customFieldResponse = await axios.get("/api/contact/customfield");
        const contactResponse = await axios.get("/api/contact/getallcontact");

        const extractedTitles = customFieldResponse.data.map(
          (custom) => custom.title
        );

        const firstContact = contactResponse.data[0];
        const extractedKeys = Object.keys(firstContact).filter((key) =>
          selectedKeys.includes(key)
        );

        setCustomsData(extractedTitles);
        setContactData(extractedKeys);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [selectedOptions, setSelectedOptions] = useState({});

  const handleOptionChange = (header, selectedOption) => {
    setSelectedOptions({
      ...selectedOptions,
      [header]: selectedOption,
    });
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const handleMapping = async () => {
    try {
      setLoading(true);

      const csvFile = file;
      const contents = await readFileAsync(csvFile);
      const csvData = contents.split("\n");

      const totalRows = csvData.length - 1; // excluding header row
      let batchSize = 100; // Start with a base batch size
      if (totalRows > 10000) {
        batchSize = 1000;
      } else if (totalRows > 5000) {
        batchSize = 500;
      } else if (totalRows > 2000) {
        batchSize = 200;
      }

      let currentRow = 0;
      const mappedData = [];

      for (let i = 1; i < csvData.length; i++) {
        const row = csvData[i].split(",");
        const mappedContact = {};

        for (const header in selectedOptions) {
          const columnIndex = csvHeaders.indexOf(header);
          const mapping = selectedOptions[header];

          if (columnIndex !== -1 && row[columnIndex] !== undefined) {
            mappedContact[mapping] = row[columnIndex].trim();
          }
        }
        mappedData.push(mappedContact);

        // Check if batch size is reached or if it's the last row
        if (mappedData.length === batchSize || i === csvData.length - 1) {
          // Send the current batch to the server
          await axios.post("/api/contact/addcsv", mappedData);

          // Update the upload progress
          currentRow += mappedData.length;
          const progress = Math.round((currentRow / totalRows) * 100);
          setUploadProgress(progress);

          // Clear the current batch
          mappedData.length = 0;
        }
      }

      onClose();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Contacts added successfully from CSV file",
      });
      fetchContactData();
    } catch (error) {
      console.error("Error mapping CSV data:", error);
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  const [showAlert, setShowAlert] = useState(true);

  const hideAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <>
      {loading ? (
        <LoadingModal uploadProgress={uploadProgress} />
      ) : (
        <div
          className="modal"
          style={{
            display: "block",
            background: "rgba(0, 0, 0, 0.7)",
            backdropFilter: " blur(5px)",
          }}
        >
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">CSV File</h5>

                <button
                  type="button"
                  className="btn-close close"
                  onClick={onClose}
                ></button>
              </div>
              <div>
                {showAlert && (
                  <div
                    className="alert alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    <div style={{ textAlign: "left", marginLeft: "20px" }}>
                      <strong>Note:</strong>
                      <ul>
                        <li>
                          <strong>1.</strong> Add at least one contact before
                          importing the data from the CSV file.
                        </li>
                        <li>
                          <strong>2.</strong> Only, 4000 rows of data can be
                          imported through the CSV file.
                        </li>
                      </ul>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={hideAlert}
                    ></button>
                  </div>
                )}
              </div>
              <div className="modal-body">
                <ul>
                  {csvHeaders.map((header, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "60px",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ fontSize: "16px" }}>{header}</div>
                      <select
                        className="btn btn-light"
                        value={selectedOptions[header] || ""}
                        onChange={(e) =>
                          handleOptionChange(header, e.target.value)
                        }
                      >
                        <option value="">Select Option</option>
                        {contactData.map((contact, contactIndex) => (
                          <option key={contactIndex} value={contact}>
                            {contact}
                          </option>
                        ))}
                        {customsData.map((custom, customIndex) => (
                          <option key={customIndex} value={custom}>
                            {custom}
                          </option>
                        ))}
                      </select>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  className="btn btn-primary w-50"
                  onClick={handleMapping}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CsvHeadersModal;
