//############################################ Tags ################################################

import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table } from "antd";
import { Box, TextField } from "@mui/material";

const Tags = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editTagsId, setEditTagsId] = useState(null);

  const [tags, setTags] = useState({
    title: "",
  });

  const fetchTagsData = async () => {
    try {
      const response = await axios.get("/api/contact/tags/gettags");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTagsData();
  }, []);

  const handleChangeTags = (e) => {
    const { name, value } = e.target;
    setTags({
      ...tags,
      [name]: value,
    });
  };

  const handleAddTags = (e) => {
    e.preventDefault();

    const tagsData = {
      ...tags,
    };
    if (editTagsId) {
      axios
        .put(`/api/contact/tags/edittags/${editTagsId}`, tagsData)
        .then((res) => {
          setTags({ title: "" });
          Swal.fire({
            title: "Tags Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchTagsData();
          });
        })
        .catch((error) => {
          Swal.fire("Error", "Error creating Tags. Please try again.", "error");
        });
    } else {
      axios
        .post("/api/contact/tags/addtags", tagsData)
        .then((res) => {
          setTags({ title: "" });
          Swal.fire({
            title: "Tags Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchTagsData();
          });
        })
        .catch((error) => {
          Swal.fire("Error", "Error creating Tags. Please try again.", "error");
        });
    }
  };

  const handleDeleteTags = async (_id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/contact/tags/delete/${_id}`)
        .then((res) => {
          fetchTagsData();
          Swal.fire("Deleted!", "Tag deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleEditTags = (_id) => {
    const selectedTags = data.find((tagid) => tagid._id === _id);

    if (selectedTags) {
      setEditTagsId(_id);
      setTags({
        title: selectedTags.title,
      });
    }
    setIsModalOpen(true);
  };

  const handleAddTagsButton = () => {
    setEditTagsId(null);
    setTags({ title: "" });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const searchContacts = () => {
    return data.filter((contact) => {
      return (
        contact.title &&
        contact.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  const getDataSource = () => {
    if (searchQuery) {
      return searchContacts();
    } else {
      return data;
    }
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: "ACTION",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditTags(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>
          <button
            onClick={() => handleDeleteTags(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">
                <i className="ri-hashtag"></i> Tags
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleAddTagsButton}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add Tags
                    </button>
                    <form
                      className="d-flex"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        className="form-control me-2"
                        type="search"
                        id="search"
                        placeholder="Search"
                        aria-label="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </form>
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                      style={{ display: isModalOpen ? "block" : "none" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editTagsId ? "Update Tags" : "Add Tags"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              <TextField
                                color="primary"
                                fullWidth
                                label="Title"
                                name="title"
                                value={tags.title}
                                onChange={handleChangeTags}
                                margin="normal"
                                required
                                autoFocus
                              />
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              onClick={handleAddTags}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {editTagsId ? "Update" : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={getDataSource()}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }}
                  pagination={{
                    pageSize: 5,
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tags;

//###################################### Using React Virtualized ###################################

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { Box, TextField } from "@mui/material";
// import { Column, Table, AutoSizer } from "react-virtualized";
// import "react-virtualized/styles.css"; // Import the react-virtualized styles
// import { Space } from "antd";

// const Tags = () => {
//   const [data, setData] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [editTagsId, setEditTagsId] = useState(null);

//   const [tags, setTags] = useState({
//     title: "",
//   });

//   const fetchTagsData = async () => {
//     try {
//       const response = await axios.get("/api/contact/tags/gettags");
//       setData(response.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchTagsData();
//   }, []);

//   const handleChangeTags = (e) => {
//     const { name, value } = e.target;
//     setTags({
//       ...tags,
//       [name]: value,
//     });
//   };

//   const handleAddTags = (e) => {
//     e.preventDefault();

//     const tagsData = {
//       ...tags,
//     };
//     if (editTagsId) {
//       axios
//         .put(`/api/contact/tags/edittags/${editTagsId}`, tagsData)
//         .then((res) => {
//           setTags({ title: "" });
//           Swal.fire({
//             title: "Tags Updated successfully!",
//             icon: "success",
//             showCancelButton: false,
//             confirmButtonColor: "#3085d6",
//             confirmButtonText: "Saved",
//           }).then(() => {
//             fetchTagsData();
//           });
//         })
//         .catch((error) => {
//           Swal.fire("Error", "Error updating Tags. Please try again.", "error");
//         });
//     } else {
//       axios
//         .post("/api/contact/tags/addtags", tagsData)
//         .then((res) => {
//           setTags({ title: "" });
//           Swal.fire({
//             title: "Tags Added successfully!",
//             icon: "success",
//             showCancelButton: false,
//             confirmButtonColor: "#3085d6",
//             confirmButtonText: "Saved",
//           }).then(() => {
//             fetchTagsData();
//           });
//         })
//         .catch((error) => {
//           Swal.fire("Error", "Error creating Tags. Please try again.", "error");
//         });
//     }
//   };

//   const handleDeleteTags = async (_id) => {
//     const confirmed = await Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#d33",
//       cancelButtonColor: "#3085d6",
//       confirmButtonText: "Yes, delete it!",
//     });

//     if (confirmed.isConfirmed) {
//       Swal.fire({
//         title: "Deleting...",
//         allowOutsideClick: false,
//         allowEscapeKey: false,
//         onBeforeOpen: () => {
//           Swal.showLoading();
//         },
//       });
//       axios
//         .delete(`/api/contact/tags/delete/${_id}`)
//         .then((res) => {
//           fetchTagsData();
//           Swal.fire("Deleted!", "Tag deleted successfully!", "success");
//         })
//         .catch((error) => {
//           Swal.fire("Error", "Something went wrong!", "error");
//         });
//     }
//   };

//   const handleEditTags = (_id) => {
//     const selectedTags = data.find((tagid) => tagid._id === _id);

//     if (selectedTags) {
//       setEditTagsId(_id);
//       setTags({
//         title: selectedTags.title,
//       });
//     }
//     setIsModalOpen(true);
//   };

//   const handleAddTagsButton = () => {
//     setEditTagsId(null);
//     setTags({ title: "" });
//   };

//   return (
//     <div className="page-content w-100">
//       <div className="container">
//         <div className="row">
//           <div className="col-12">
//             <div className="page-title-box d-sm-flex align-items-center justify-content-between">
//               <h4 className="mb-sm-0 py-3">
//                 <i className="ri-hashtag"></i> Tags
//               </h4>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-xxl-12">
//             <div id="contactList" className="card">
//               <div className="card-header py-3">
//                 <div className="d-flex align-items-center flex-wrap gap-2">
//                   <div className="d-flex flex-wrap flex-grow-1 gap-2">
//                     <button
//                       type="button"
//                       className="btn btn-primary add-btn"
//                       onClick={handleAddTagsButton}
//                       data-bs-toggle="modal"
//                       data-bs-target="#exampleModal"
//                     >
//                       <i className="ri-add-fill me-1 align-bottom"></i> Add Tags
//                     </button>
//                     <div
//                       className="modal fade"
//                       id="exampleModal"
//                       tabIndex="-1"
//                       aria-labelledby="exampleModalLabel"
//                       aria-hidden={!isModalOpen}
//                       style={{ display: isModalOpen ? "block" : "none" }}
//                     >
//                       <div className="modal-dialog">
//                         <div className="modal-content">
//                           <div className="modal-header">
//                             <h5 className="modal-title" id="exampleModalLabel">
//                               {editTagsId ? "Update Tags" : "Add Tags"}
//                             </h5>
//                             <button
//                               type="button"
//                               className="btn-close"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                             ></button>
//                           </div>
//                           <div className="modal-body">
//                             <Box>
//                               <TextField
//                                 color="primary"
//                                 fullWidth
//                                 label="Title"
//                                 name="title"
//                                 value={tags.title}
//                                 onChange={handleChangeTags}
//                                 margin="normal"
//                                 required
//                                 autoFocus
//                               />
//                             </Box>
//                           </div>
//                           <div className="modal-footer">
//                             <button
//                               onClick={handleAddTags}
//                               type="button"
//                               className="btn btn-primary"
//                               data-bs-dismiss="modal"
//                             >
//                               {editTagsId ? "Update" : "Save"}
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex-shrink-0"></div>
//                 </div>
//                 <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
//                   <div className="flex-shrink-0">
//                     <div className="hstack text-nowrap gap-2 align-item-center"></div>
//                   </div>
//                 </div>
//               </div>
//               <div className="card-body" style={{ height: "400px" }}>
//                 <AutoSizer>
//                   {({ height, width }) => (
//                     <Table
//                       width={width}
//                       height={height}
//                       headerHeight={40}
//                       rowHeight={40}
//                       rowCount={data.length}
//                       rowGetter={({ index }) => data[index]}
//                       rowClassName="table-row"
//                     >
//                       <Column
//                         label="ID"
//                         dataKey="id"
//                         width={50}
//                         cellRenderer={({ rowIndex }) => rowIndex + 1}
//                       />
//                       <Column label="Title" dataKey="title" width={300} />
//                       <Column
//                         label="Action"
//                         dataKey="action"
//                         width={150}
//                         cellRenderer={({ rowData }) => (
//                           <Space size="middle">
//                             <button
//                               type="button"
//                               data-bs-toggle="modal"
//                               data-bs-target="#exampleModal"
//                               className="edit-btn"
//                               onClick={() => handleEditTags(rowData._id)}
//                             >
//                               <i className="ri-pencil-fill"></i>
//                             </button>
//                             <button
//                               onClick={() => handleDeleteTags(rowData._id)}
//                               className="delete-btn"
//                             >
//                               <i className="ri-delete-bin-fill"></i>
//                             </button>
//                           </Space>
//                         )}
//                       />
//                     </Table>
//                   )}
//                 </AutoSizer>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Tags;
