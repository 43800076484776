// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// function Protected(props) {
//   const { Component } = props;
//   const navigate = useNavigate();

//   useEffect(() => {
//     let login = localStorage.getItem("token");
//     if (!login) {
//       navigate("/");
//     }
//   });
//   return (
//     <div>
//       <Component />
//     </div>
//   );
// }

// export default Protected;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Protected(props) {
  const { Component, requiredRole } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    if (!token) {
      navigate("/");
    } else if (requiredRole && role !== requiredRole) {
      navigate("/dashboard");
    }
  }, [requiredRole, navigate]);

  return <Component />;
}

export default Protected;
