import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table, Button, Modal } from "antd";
import moment from "moment";
import { Box, TextField, Grid, Select } from "@mui/material";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const CurrentWebinar = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editCurrentWebinarId, setEditCurrentWebinarId] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [nextCustomFieldType, setNextCustomFieldType] = useState("");
  const [customsData, setCustomsData] = useState([]);
  const [currentwebinar, setCurrentWebinar] = useState({
    programname: "",
    futurewebinarid: "",
    transitiontime: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  // Fetch Contact data on component mount
  useEffect(() => {
    fetchCustomsData();
    fetchCurrentWebinarData();
    // Set up the interval to periodically check for updates
    const intervalId = setInterval(() => {
      fetchCurrentWebinarData(); // This will update the state every intervalTime
    }, 10000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const fetchCustomsData = async () => {
    try {
      const response = await axios.get("/api/customfieldkey/getall");
      setCustomsData(response.data);
    } catch (error) {
      console.error("Error fetching customs data:", error);
    }
  };

  const handleCustomFieldValueChange = (key, value) => {
    setCustomFields((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleAddCustomField = () => {
    if (nextCustomFieldType) {
      setCustomFields((prev) => ({
        ...prev,
        [nextCustomFieldType]: "", // Initialize with an empty value
      }));
      setNextCustomFieldType(""); // Reset selection
    }
  };

  const handleRemoveCustomField = useCallback((key) => {
    setCustomFields((prev) => {
      const newFields = { ...prev };
      delete newFields[key];
      return newFields;
    });
  }, []);

  // Function to convert local date and time to IST string
  const toISTString = (localDateTime) => {
    return moment(localDateTime)
      .utcOffset("+05:30")
      .format("YYYY-MM-DDTHH:mm:ss");
  };

  const [loading, setLoading] = useState(false);

  const handleChangeCurrentWebinar = (e) => {
    const { name, value } = e.target;
    setCurrentWebinar({
      ...currentwebinar,
      [name]: value,
    });

    if (name === "webinarid" || name === "futurewebinarid") {
      validateIDs({ ...currentwebinar, [name]: value });
    }
  };

  const validateIDs = (updatedWebinar) => {
    if (updatedWebinar.webinarid === updatedWebinar.futurewebinarid) {
      setError({
        webinarIDError: "Webinar ID and Future Webinar ID cannot be the same.",
        futureWebinarIDError:
          "Webinar ID and Future Webinar ID cannot be the same.",
      });
    } else {
      setError({
        webinarIDError: "",
        futureWebinarIDError: "",
      });
    }
  };

  // Function to handle transition time picker change and store IST string
  const handleDatePickertransitiontimeChange = (dateTimeString) => {
    setCurrentWebinar({
      ...currentwebinar,
      transitiontime: toISTString(dateTimeString),
    });
  };

  const handleAddCurrentWebinarButton = () => {
    setEditCurrentWebinarId(null);
    setCurrentWebinar({
      programcode: "",
      futurewebinarid: "",
      transitiontime: "",
    });
    setCustomFields([]);
  };

  const fetchCurrentWebinarData = async () => {
    try {
      const response = await axios.get("/api/currentwebinar/getall");

      // Update state only if data is different to prevent re-rendering unnecessarily
      if (JSON.stringify(response.data) !== JSON.stringify(data)) {
        setData(response.data);
      }

      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCurrentWebinar = (e) => {
    e.preventDefault();

    const currentwebinarData = {
      ...currentwebinar,
      customFields: customFields,
    };

    if (editCurrentWebinarId) {
      axios
        .put(
          `/api/currentwebinar/update/${editCurrentWebinarId}`,
          currentwebinarData
        )
        .then((res) => {
          setCurrentWebinar({
            programcode: "",
            futurewebinarid: "",
            transitiontime: "",
          });
          setCustomFields([]);
          Swal.fire({
            title: "Webinar Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchCurrentWebinarData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Webinar. Please try again.",
            "error"
          );
        });
    } else {
      axios
        .post("/api/currentwebinar/create", currentwebinarData)
        .then((res) => {
          setCurrentWebinar({
            programcode: "",
            futurewebinarid: "",
            transitiontime: "",
          });
          setCustomFields([]);
          Swal.fire({
            title: "Webinar Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchCurrentWebinarData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Webinar. Please try again.",
            "error"
          );
        });
    }
  };

  const handleEditCurrentWebinar = (_id) => {
    const selectedCurrentWebinar = data.find((fwid) => fwid._id === _id);

    if (selectedCurrentWebinar) {
      setEditCurrentWebinarId(_id);
      if (selectedCurrentWebinar.isalledit == true) {
        setIsEditing(true);
      }
      setCurrentWebinar({
        programcode: selectedCurrentWebinar.programcode,
        futurewebinarid: selectedCurrentWebinar.futurewebinarid,
        transitiontime: selectedCurrentWebinar.transitiontime
          ? moment(selectedCurrentWebinar.transitiontime).format(
              "YYYY-MM-DDTHH:mm"
            )
          : "",
        programname: selectedCurrentWebinar.programname,
        webinarid: selectedCurrentWebinar.webinarid,
        webinardate: selectedCurrentWebinar.webinardate
          ? moment(selectedCurrentWebinar.webinardate).format(
              "YYYY-MM-DDTHH:mm"
            )
          : "",
        webinartag: selectedCurrentWebinar.webinartag,
        eventtype: selectedCurrentWebinar.eventtype,
        duration: selectedCurrentWebinar.duration,
        template: selectedCurrentWebinar.template,
        wagrouplink: selectedCurrentWebinar.wagrouplink,
        webinarregistrationlink: selectedCurrentWebinar.webinarregistrationlink,
        wagroupname: selectedCurrentWebinar.wagroupname,
      });

      setCustomFields(selectedCurrentWebinar.customFields);
    }
    setIsModalOpen(true);
  };

  const handleDeleteCurrentWebinar = async (_id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/currentwebinar/delete/${_id}`)
        .then((res) => {
          fetchCurrentWebinarData();
          Swal.fire("Deleted!", "Webinar deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  // Extract custom field keys from the data
  const customFieldColumns = [];

  data.forEach((webinar) => {
    // Check if customFields exists and is an object
    if (webinar.customFields && typeof webinar.customFields === "object") {
      Object.keys(webinar.customFields).forEach((key) => {
        if (!customFieldColumns.some((col) => col.key === key)) {
          customFieldColumns.push({
            title: key, // Column header as the custom field key
            dataIndex: ["customFields", key], // Access the value with dot notation
            key: key,
            render: (text) => text || "-", // Render the value or placeholder if empty
            ellipsis: true,
          });
        }
      });
    }
  });

  //Save the Duplicate details
  const [duplicatedWebinar, setDuplicatedWebinar] = useState({
    programcode: "",
    futurewebinarid: "",
    transitiontime: "",
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDuplicateWebinar = (webinar) => {
    const duplicated = {
      ...webinar, // Format the date
      transitiontime: formatDate(webinar.transitiontime),
    };

    setDuplicatedWebinar(duplicated);
    setIsModalVisible(true); // Open the modal for editing
  };

  // Function to format date to 'yyyy-MM-ddThh:mm'
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleSaveDuplicatedWebinar = async () => {
    try {
      // Create a copy of the duplicatedWebinar object excluding the unwanted fields
      const { _id, day, date, time, ...cleanedWebinar } = duplicatedWebinar;

      // Example URL of your backend API
      const url = "/api/currentwebinar/create"; // Change this to your actual API endpoint

      // Send cleanedWebinar (without day, date, and time) to the backend API
      const response = await axios.post(url, cleanedWebinar);

      if (response.status === 201 || response.status === 200) {
        console.log("Duplicated Webinar saved successfully:", response.data);

        // Optionally, update the table data with the new entry
        // setData((prevData) => [...prevData, response.data]);

        // Fetch updated webinar data
        fetchCurrentWebinarData();

        // Show success SweetAlert
        Swal.fire({
          title: "Success!",
          text: "The duplicated webinar has been successfully added.",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Close the modal after saving
        setIsModalVisible(false);
      } else {
        console.error(
          "Failed to save duplicated webinar:",
          response.statusText
        );

        // Show error SweetAlert
        Swal.fire({
          title: "Error!",
          text: "Failed to save the duplicated webinar. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error saving duplicated webinar:", error);
      // Show error SweetAlert in case of an exception
      Swal.fire({
        title: "Error!",
        text: "An error occurred while saving the duplicated webinar.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const columns = [
    // ID
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    // Program Code
    {
      title: "PROG. Code",
      dataIndex: "programcode",
      key: "programcode",
      ellipsis: true,
    },
    // Program Name
    {
      title: "PROG. NAME",
      dataIndex: "programname",
      key: "programname",
      ellipsis: true,
    },
    // Webinar Date & Time
    {
      title: "Webinar Date & Time",
      dataIndex: "webinardate",
      key: "webinardate",
      ellipsis: true,
      render: (text) => {
        // Check if the text is null, blank, or undefined
        if (!text || text.trim() === "") {
          return "-"; // Return a placeholder if invalid
        }

        // Convert the text (date) to a Date object
        const date = new Date(text);

        // Check if the date is valid and return the formatted date, otherwise return a placeholder
        return isNaN(date.getTime()) ? "-" : date.toLocaleString();
      },
    },
    // Date
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
    },
    // Day
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
      ellipsis: true,
    },
    // Time
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      ellipsis: true,
    },
    // Duration
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      ellipsis: true,
    },
    // Webinar Tag
    {
      title: "Webinar Tag",
      dataIndex: "webinartag",
      key: "webinartag",
      ellipsis: true,
    },
    // Event Type
    {
      title: "Event Type",
      dataIndex: "eventtype",
      key: "eventtype",
      ellipsis: true,
    },
    // Webinar ID
    {
      title: "Webinar ID",
      dataIndex: "webinarid",
      key: "webinarid",
      ellipsis: true,
    },
    // Template no.

    {
      title: "Template No.",
      dataIndex: "template",
      key: "template",
      ellipsis: true,
    },
    // WA Group Joining Link
    {
      title: "WA Group Joining Link",
      dataIndex: "wagrouplink",
      key: "wagrouplink",
      ellipsis: true,
    },
    // Webinar Registration Link
    {
      title: "Webinar Registration Link",
      dataIndex: "webinarregistrationlink",
      key: "webinarregistrationlink",
      ellipsis: true,
    },
    // WA Group Name
    {
      title: "WA Group Name",
      dataIndex: "wagroupname",
      key: "wagroupname",
      ellipsis: true,
    },
    // Add dynamically generated custom field columns here
    ...customFieldColumns,
    // Transition Time
    {
      title: "Transition Time",
      dataIndex: "transitiontime",
      key: "transitiontime",
      ellipsis: true,
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    // Future Webinar ID
    {
      title: "Future Webinar ID",
      dataIndex: "futurewebinarid",
      key: "futurewebinarid",
      ellipsis: true,
    },
    // ACTION
    {
      title: "ACTION",
      key: "action",
      width: 150,
      fixed: "right",

      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditCurrentWebinar(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>
          <button
            onClick={() => handleDeleteCurrentWebinar(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
          <button
            onClick={() => handleDuplicateWebinar(record)}
            className="edit-btn"
          >
            <i class="ri-file-copy-line"></i>
          </button>
        </Space>
      ),
    },
  ];

  const [error, setError] = useState({
    webinarIDError: "",
    futureWebinarIDError: "",
  });

  const isValidURL = (url) => {
    // Regular expression to validate URL format
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  // Function to handle date picker change and store IST string
  const handleDatePickerChange = (dateTimeString) => {
    setCurrentWebinar({
      ...currentwebinar,
      webinardate: toISTString(dateTimeString),
    });
  };

  // Webhook functionality

  const [selectedProgramCode, setSelectedProgramCode] = useState("");

  // Handling selection change
  const handleProgramCodeChange = (e) => {
    setSelectedProgramCode(e.target.value);
  };

  // Trigger webhook function to hit API with the selected program code
  const handleTriggerWebhook = async () => {
    if (!selectedProgramCode) return;

    try {
      const response = await axios.post("/api/webhook/sending", {
        programcode: selectedProgramCode,
      });
      console.log("Webhook response:", response.data);
      // Show success alert
      Swal.fire({
        icon: "success",
        title: "Webhook triggered successfully!",
        text: "The selected program code has been sent.",
        showConfirmButton: false, // No "Saved" button
        timer: 2000,
      });
    } catch (error) {
      console.error("Error triggering webhook:", error);
      // Show error alert
      Swal.fire({
        icon: "error",
        title: "Failed to trigger webhook",
        text: "There was an issue sending the program code.",
        showConfirmButton: false, // No "Saved" button
        timer: 2000,
      });
    }
  };

  // const handleTriggerWebhook = async () => {
  //   try {
  //     const response = await axios.post("/api/webhook/sending"); // Update the URL if needed
  //     Swal.fire({
  //       title: "Webhook triggered successfully!",
  //       icon: "success",
  //       showCancelButton: false,
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //   } catch (error) {
  //     Swal.fire({
  //       title: "Error",
  //       text: "Failed to trigger webhook. Please try again.",
  //       icon: "error",
  //     });
  //   }
  // };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Current Webinar</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    <div className="d-flex gap-2">
                      <div>
                        <select
                          class="form-select"
                          value={selectedProgramCode}
                          onChange={handleProgramCodeChange}
                        >
                          <option value="" disabled>
                            --Select Program Code--
                          </option>
                          {data.map((webinar) => (
                            <option
                              key={webinar._id}
                              value={webinar.programcode}
                            >
                              {webinar.programcode}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={handleTriggerWebhook}
                          disabled={!selectedProgramCode}
                        >
                          Trigger Webhook
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-success add-btn"
                      onClick={handleAddCurrentWebinarButton}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Current
                      Webinar
                    </button>
                    {/* Current Webinar Modal */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                      style={{ display: isModalOpen ? "block" : "none" }}
                    >
                      <div
                        className="modal-dialog modal-lg modal-dialog-centered"
                        style={{ maxWidth: "62%" }}
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editCurrentWebinarId
                                ? "Update Webinar"
                                : "Add Webinar"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              {/* Group Of 3 */}
                              <Grid
                                container
                                spacing={1}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {/* Program Name */}
                                <Grid item xs={4}>
                                  <h6>Program Code</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    label="Program Code"
                                    name="programcode"
                                    value={currentwebinar.programcode}
                                    onChange={handleChangeCurrentWebinar}
                                    // margin="normal"
                                    autoFocus
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <h6>Future Webinar ID</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    label="Future Webinar ID"
                                    name="futurewebinarid"
                                    value={currentwebinar.futurewebinarid}
                                    onChange={handleChangeCurrentWebinar}
                                    // margin="normal"
                                    autoFocus
                                    error={!!error.futureWebinarIDError}
                                    helperText={error.futureWebinarIDError}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <h6>Transition Time</h6>
                                  <input
                                    type="datetime-local"
                                    id="transitiontime"
                                    name="transitiontime"
                                    value={currentwebinar.transitiontime}
                                    onChange={(e) =>
                                      handleDatePickertransitiontimeChange(
                                        e.target.value
                                      )
                                    }
                                    className="form-select w-100"
                                  />
                                </Grid>
                              </Grid>
                              {isEditing && (
                                <div>
                                  {/* Group Of 3 */}
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* Program Name */}
                                    <Grid item xs={4}>
                                      <h6 className="mt-3">Program Name</h6>
                                      <TextField
                                        size="small"
                                        color="primary"
                                        fullWidth
                                        name="programname"
                                        value={currentwebinar.programname}
                                        onChange={handleChangeCurrentWebinar}
                                      />
                                    </Grid>

                                    {/* Webinar ID */}
                                    <Grid item xs={4}>
                                      <h6 className="mt-3">Webinar ID</h6>
                                      <TextField
                                        size="small"
                                        color="primary"
                                        fullWidth
                                        name="webinarid"
                                        value={currentwebinar.webinarid}
                                        onChange={handleChangeCurrentWebinar}
                                        error={!!error.webinarIDError}
                                        helperText={error.webinarIDError}
                                      />
                                    </Grid>
                                    {/* )} */}

                                    {/* Webinar Date and Time */}

                                    <Grid item xs={4}>
                                      <h6 className="mt-3">Webinar Date</h6>
                                      <input
                                        type="datetime-local"
                                        id="webinardate"
                                        name="webinardate"
                                        value={currentwebinar.webinardate}
                                        onChange={(e) =>
                                          handleDatePickerChange(e.target.value)
                                        }
                                        className="form-select w-100"
                                      />
                                    </Grid>
                                  </Grid>
                                  {/* Group Of 3 */}
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* Webinar Tag */}

                                    <Grid item xs={4}>
                                      <h6 className="mt-3">Webinar Tag</h6>
                                      <TextField
                                        size="small"
                                        color="primary"
                                        fullWidth
                                        name="webinartag"
                                        value={currentwebinar.webinartag}
                                        onChange={handleChangeCurrentWebinar}
                                      />
                                    </Grid>

                                    {/* Event Type */}

                                    <Grid item xs={4}>
                                      <h6 className="mt-3">Event Type</h6>
                                      {/* Event Type Selector */}
                                      <select
                                        className="form-select mt-2"
                                        aria-label="Default select example"
                                        name="eventtype"
                                        value={currentwebinar.eventtype}
                                        onChange={handleChangeCurrentWebinar}
                                      >
                                        <option selected value="" disabled>
                                          --Select Event Type--
                                        </option>
                                        <option
                                          value="zoom meeting"
                                          name="whatsapp"
                                        >
                                          Zoom meeting
                                        </option>
                                        <option
                                          value="zoom webinar"
                                          name="facebook"
                                        >
                                          Zoom Webinar
                                        </option>
                                        <option
                                          value="ever webinar"
                                          name="instagram"
                                        >
                                          Ever Webinar
                                        </option>
                                        <option
                                          value="webinar jam"
                                          name="instagram"
                                        >
                                          Webinar Jam
                                        </option>
                                      </select>
                                    </Grid>

                                    {/* Duration */}

                                    <Grid item xs={4}>
                                      <h6 className="mt-3">Duration</h6>
                                      <TextField
                                        size="small"
                                        color="primary"
                                        fullWidth
                                        name="duration"
                                        value={currentwebinar.duration}
                                        onChange={handleChangeCurrentWebinar}
                                      />
                                    </Grid>
                                  </Grid>
                                  {/* Group Of 3 */}
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* Template No. */}

                                    <Grid item xs={4}>
                                      <h6 className="mt-3">Template No.</h6>
                                      <TextField
                                        size="small"
                                        color="primary"
                                        fullWidth
                                        name="template"
                                        value={currentwebinar.template}
                                        onChange={handleChangeCurrentWebinar}
                                      />
                                    </Grid>

                                    {/* Wagroup Link */}

                                    <Grid item xs={4}>
                                      <h6 className="mt-3">Wagroup link</h6>
                                      <TextField
                                        size="small"
                                        color="primary"
                                        fullWidth
                                        name="wagrouplink"
                                        value={currentwebinar.wagrouplink}
                                        onChange={handleChangeCurrentWebinar}
                                        error={
                                          currentwebinar.wagrouplink &&
                                          !isValidURL(
                                            currentwebinar.wagrouplink
                                          )
                                        }
                                        helperText={
                                          currentwebinar.wagrouplink &&
                                          !isValidURL(
                                            currentwebinar.wagrouplink
                                          ) &&
                                          "Please enter a valid URL"
                                        }
                                      />
                                    </Grid>

                                    {/* Webinar Registration Link */}

                                    <Grid item xs={4}>
                                      <h6 className="mt-3">
                                        Webinar Registration Link
                                      </h6>
                                      <TextField
                                        size="small"
                                        color="primary"
                                        fullWidth
                                        name="webinarregistrationlink"
                                        value={
                                          currentwebinar.webinarregistrationlink
                                        }
                                        onChange={handleChangeCurrentWebinar}
                                        error={
                                          currentwebinar.webinarregistrationlink &&
                                          !isValidURL(
                                            currentwebinar.webinarregistrationlink
                                          )
                                        }
                                        helperText={
                                          currentwebinar.webinarregistrationlink &&
                                          !isValidURL(
                                            currentwebinar.webinarregistrationlink
                                          ) &&
                                          "Please enter a valid URL"
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  {/* Group Of 3 */}
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* WA Group Name */}

                                    <Grid item xs={4}>
                                      <h6 className="mt-3">WA Group Name</h6>
                                      <TextField
                                        size="small"
                                        color="primary"
                                        fullWidth
                                        name="wagroupname"
                                        value={currentwebinar.wagroupname}
                                        onChange={handleChangeCurrentWebinar}
                                      />
                                    </Grid>

                                    <Grid item xs={4}></Grid>
                                    <Grid item xs={4}></Grid>
                                  </Grid>

                                  {/* Custom Fields */}
                                  {/* <div>
                                    {Object.entries(customFields || {}).map(
                                      ([key, value]) => {
                                        // Only render if the value exists and is not empty
                                        if (!value) return null;

                                        return (
                                          <div key={key}>
                                            <Grid
                                              container
                                              spacing={1}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Grid item xs={11}>
                                                <TextField
                                                  size="small"
                                                  fullWidth
                                                  label={key}
                                                  type="text"
                                                  margin="normal"
                                                  value={value}
                                                  onChange={(e) =>
                                                    handleCustomFieldValueChange(
                                                      key,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Grid>
                                              <Grid item xs={1}>
                                                <MinusCircleOutlined
                                                  style={{
                                                    fontSize: "25px",
                                                    textAlign: "center",
                                                  }}
                                                  onClick={() =>
                                                    handleRemoveCustomField(key)
                                                  }
                                                />
                                              </Grid>
                                            </Grid>
                                          </div>
                                        );
                                      }
                                    )}

                                    {Object.values(customFields || {}).some(
                                      (value) => value
                                    ) && (
                                      <Grid
                                        container
                                        spacing={1}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "10px",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        <Grid item xs={9}>
                                          <select
                                            className="form-select"
                                            margin="normal"
                                            value={nextCustomFieldType}
                                            onChange={(e) =>
                                              setNextCustomFieldType(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="" selected disabled>
                                              --Select a Custom Field--
                                            </option>
                                            {customsData.map((custom) => (
                                              <option
                                                key={custom._id}
                                                value={custom.key}
                                              >
                                                {custom.key}
                                              </option>
                                            ))}
                                          </select>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Button
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            type="dashed"
                                            onClick={handleAddCustomField}
                                            icon={<PlusOutlined />}
                                          >
                                            Add field
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    )}
                                  </div> */}

                                  {/* Custom Fields */}
                                  <div>
                                    {Object.entries(customFields || {}).map(
                                      ([key, value]) => {
                                        // Render the custom field regardless of whether the value is empty
                                        return (
                                          <div key={key}>
                                            <Grid
                                              container
                                              spacing={1}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Grid item xs={11}>
                                                <TextField
                                                  size="small"
                                                  fullWidth
                                                  label={key}
                                                  type="text"
                                                  margin="normal"
                                                  value={value} // This will display the current value
                                                  onChange={(e) =>
                                                    handleCustomFieldValueChange(
                                                      key,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Grid>
                                              <Grid item xs={1}>
                                                <MinusCircleOutlined
                                                  style={{
                                                    fontSize: "25px",
                                                    textAlign: "center",
                                                  }}
                                                  onClick={() =>
                                                    handleRemoveCustomField(key)
                                                  }
                                                />
                                              </Grid>
                                            </Grid>
                                          </div>
                                        );
                                      }
                                    )}

                                    {/* Section for Adding New Custom Fields - Show always when in edit mode */}
                                    <Grid
                                      container
                                      spacing={1}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "10px",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      <Grid item xs={9}>
                                        <select
                                          className="form-select"
                                          margin="normal"
                                          value={nextCustomFieldType}
                                          onChange={(e) =>
                                            setNextCustomFieldType(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="" disabled>
                                            --Select a Custom Field--
                                          </option>
                                          {customsData.map((custom) => (
                                            <option
                                              key={custom._id}
                                              value={custom.key}
                                            >
                                              {custom.key}
                                            </option>
                                          ))}
                                        </select>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          type="dashed"
                                          onClick={handleAddCustomField}
                                          icon={<PlusOutlined />}
                                        >
                                          Add field
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              )}
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              onClick={handleAddCurrentWebinar}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {editCurrentWebinarId ? "Update" : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* Modal for editing duplicated webinar */}
                      <Modal
                        title="Edit Duplicated Webinar"
                        visible={isModalVisible}
                        onOk={handleSaveDuplicatedWebinar}
                        onCancel={() => setIsModalVisible(false)}
                        width={800}
                      >
                        {/* Group Of 3 */}
                        <Grid
                          container
                          spacing={1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* Program Name */}
                          <Grid item xs={4}>
                            {/* Form Fields */}
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Program Code"
                              name="programcode"
                              value={duplicatedWebinar?.programcode || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  programcode: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Future Webinar ID"
                              name="futurewebinarid"
                              value={duplicatedWebinar?.futurewebinarid || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  futurewebinarid: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Transition Time"
                              name="transitiontime"
                              type="datetime-local"
                              value={duplicatedWebinar?.transitiontime || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  transitiontime: e.target.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </Modal>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  loading={loading}
                  rowKey="_id"
                  scroll={{ x: true }}
                  // style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }} // Show scrollbar only if data is lengthy
                  pagination={{
                    pageSize: 5,
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentWebinar;
