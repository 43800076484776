import React, { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";

const Testing = () => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getTagsData = async () => {
      try {
        const response = await axios.get("/api/contact/tags/gettags");
        const responseData = response.data;

        const getTagsName = responseData.map((tag) => tag.title);

        setOptions(getTagsName);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getTagsData();
  }, []);

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <h1>
          <span style={{ color: "#0D6EFD" }}>Testing</span>
        </h1>
        <h2
          style={{
            color: "rgb(211, 32, 32)",
          }}
        >
          For Developers Only
        </h2>
      </div>
      <div
        style={{
          border: "1px solid grey",
          padding: "20px",
          borderRadius: "10px",
          width: "100vw",
          height: "50vh",
        }}
      >
        <h6>Multiple Select</h6>
        <div style={{ width: "30%" }}>
          <Multiselect
            isObject={false}
            onRemove={(e) => {
              // console.log(e);
            }}
            onSelect={(e) => {
              // console.log(e);
            }}
            options={options}
            showCheckbox
          />
        </div>
      </div>
    </div>
  );
};

export default Testing;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { Space, Table, Button } from "antd";
// import { Box, TextField, Grid } from "@mui/material";
// import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

// const CurrentWebinar = () => {
//   const [data, setData] = useState([]);
//   const [currentWebinars, setCurrentWebinars] = useState(null);
//   const [editFutureWebinarId, setEditFutureWebinarId] = useState(null);
//   const [customFields, setCustomFields] = useState([]);
//   const [nextCustomFieldType, setNextCustomFieldType] = useState("");
//   const [customsData, setCustomsData] = useState([]);
//   const [futurewebinar, setFutureWebinar] = useState({
//     programname: "",
//     webinardate: "",
//     eventtype: "",
//     webinarid: "",
//     wagrouplink: "",
//     template: "",
//     webinarregistrationlink: "",
//     customFields: "",
//   });
//   useEffect(() => {
//     const fetchWebinarData = async () => {
//       try {
//         const response = await axios.get("/api/futurewebinar/getall");
//         const webinarData = response.data;

//         // Filter out webinars for the current date
//         const currentDate = new Date();
//         const currentWebinars = webinarData.filter(
//           (webinar) =>
//             new Date(webinar.webinardate).toDateString() ===
//             currentDate.toDateString()
//         );

//         setCurrentWebinars(currentWebinars);
//       } catch (error) {
//         console.error("Error fetching webinar data:", error);
//       }
//     };

//     fetchWebinarData();

//     // Fetch updated webinar data every 60 seconds
//     const interval = setInterval(fetchWebinarData, 60000);

//     return () => clearInterval(interval);
//   }, []);

//   const fetchCustomsData = async () => {
//     try {
//       const response = await axios.get("/api/contact/customfield");
//       setCustomsData(response.data);
//     } catch (error) {
//       console.error("Error fetching customs data:", error);
//     }
//   };

//   const fetchFutureWebinarData = async () => {
//     try {
//       const response = await axios.get("/api/futurewebinar/future");
//       setData(response.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchFutureWebinarData();
//     fetchCustomsData();
//   }, []);

//   const handleAddFutureWebinar = (e) => {
//     e.preventDefault();

//     const futurewebinarData = {
//       ...futurewebinar,
//       customFields: customFields,
//     };
//     console.log(futurewebinarData);

//     if (editFutureWebinarId) {
//       axios
//         .put(
//           `/api/futurewebinar/update/${editFutureWebinarId}`,
//           futurewebinarData
//         )

//         .then((res) => {
//           setFutureWebinar({
//             programname: "",
//             webinardate: "",
//             eventtype: "",
//             webinarid: "",
//             wagrouplink: "",
//             template: "",
//             webinarregistrationlink: "",
//           });
//           setCustomFields([]);
//           Swal.fire({
//             title: "Future Webinar Updated successfully!",
//             icon: "success",
//             showCancelButton: false,
//             confirmButtonColor: "#3085d6",
//             confirmButtonText: "Saved",
//           }).then(() => {
//             fetchFutureWebinarData();
//           });
//         })
//         .catch((error) => {
//           Swal.fire("Error", "Error creating Tags. Please try again.", "error");
//         });
//     } else {
//       axios
//         .post("/api/futurewebinar/create", futurewebinarData)

//         .then((res) => {
//           setFutureWebinar({
//             programname: "",
//             webinardate: "",
//             eventtype: "",
//             webinarid: "",
//             wagrouplink: "",
//             template: "",
//             webinarregistrationlink: "",
//           });
//           setCustomFields([]);
//           Swal.fire({
//             title: "Future Webinar Added successfully!",
//             icon: "success",
//             showCancelButton: false,
//             confirmButtonColor: "#3085d6",
//             confirmButtonText: "Saved",
//           }).then(() => {
//             fetchFutureWebinarData();
//           });
//         })
//         .catch((error) => {
//           Swal.fire(
//             "Error",
//             "Error creating Future Webinar. Please try again.",
//             "error"
//           );
//         });
//     }
//   };

//   const handleAddCustomField = () => {
//     const selectedCustom = customsData.find(
//       (custom) => custom.title === nextCustomFieldType
//     );

//     if (selectedCustom) {
//       setCustomFields([
//         ...customFields,
//         { title: selectedCustom.title, value: "" },
//       ]);
//       setNextCustomFieldType(""); // Reset the next custom field type
//     }
//   };

//   const handleCustomFieldValueChange = (index, event) => {
//     const updatedFields = [...customFields];
//     updatedFields[index].value = event.target.value;
//     setCustomFields(updatedFields);
//   };
//   const handleRemoveCustomField = (index) => {
//     const updatedFields = [...customFields];
//     updatedFields.splice(index, 1);
//     setCustomFields(updatedFields);
//   };

//   const handleDeleteFutureWebinar = async (_id) => {
//     try {
//       // Show SweetAlert2 confirm dialog
//       const confirmed = await Swal.fire({
//         title: "Are you sure?",
//         text: "You won't be able to revert this!",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#d33",
//         cancelButtonColor: "#3085d6",
//         confirmButtonText: "Yes, delete it!",
//       });

//       if (confirmed.isConfirmed) {
//         // Show a loading spinner while holding the action
//         Swal.fire({
//           title: "Deleting...",
//           allowOutsideClick: false,
//           allowEscapeKey: false,
//           onBeforeOpen: () => {
//             Swal.showLoading();
//           },
//         });

//         // Perform delete operation
//         await axios.delete(`/api/futurewebinar/delete/${_id}`);
//         // Remove the deleted webinar from the UI
//         setCurrentWebinars(
//           currentWebinars.filter((webinar) => webinar._id !== _id)
//         );
//         // Show success message
//         Swal.fire("Deleted!", "This Webinar deleted successfully!", "success");
//       }
//     } catch (error) {
//       console.error("Error deleting webinar:", error);
//       Swal.fire(
//         "Error",
//         "Something went wrong while deleting the webinar!",
//         "error"
//       );
//     }
//   };

//   const handleAddFutureWebinarButton = () => {
//     setEditFutureWebinarId(null);
//     setFutureWebinar({
//       programname: "",
//       webinardate: "",
//       eventtype: "",
//       webinarid: "",
//       wagrouplink: "",
//       template: "",
//       webinarregistrationlink: "",
//       customFields: "",
//     });
//     setCustomFields([]);
//   };

//   const handleEditFutureWebinar = (_id) => {
//     const selectedFutureWebinar = data.find((fwid) => fwid._id === _id);

//     if (selectedFutureWebinar) {
//       setEditFutureWebinarId(_id); // Set the ID of the contact being edited
//       setFutureWebinar({
//         programname: selectedFutureWebinar.programname,
//         // webinardate: selectedFutureWebinar.webinardate,
//         webinardate: selectedFutureWebinar.webinardate
//           ? new Date(selectedFutureWebinar.webinardate)
//               .toISOString()
//               .slice(0, 16)
//           : "",
//         eventtype: selectedFutureWebinar.eventtype,
//         webinarid: selectedFutureWebinar.webinarid,
//         wagrouplink: selectedFutureWebinar.wagrouplink,
//         template: selectedFutureWebinar.template,
//         webinarregistrationlink: selectedFutureWebinar.webinarregistrationlink,
//       }); // Populate the form fields with the selected contact data
//       setCustomFields(
//         selectedFutureWebinar.customFields.map((field) => ({
//           title: field.title,
//           value: field.value,
//         }))
//       );
//     }
//     // setIsModalOpen(true);
//   };

//   const handleChangeFutureWebinar = (e) => {
//     const { name, value } = e.target;
//     setFutureWebinar({
//       ...futurewebinar,
//       [name]: value,
//     });
//   };

//   const columns = [
//     {
//       title: "ID",
//       key: "id",
//       render: (text, record, index) => index + 1,
//       fixed: "left",
//       width: 50,
//     },

//     {
//       title: "PROG. NAME",
//       dataIndex: "programname",
//       key: "programname",
//       ellipsis: true,
//     },
//     {
//       title: "Webinar Date",
//       dataIndex: "webinardate",
//       key: "webinardate",
//       ellipsis: true,
//       render: (createdAt) => new Date(createdAt).toLocaleString(),
//     },
//     {
//       title: "Event Type",
//       dataIndex: "eventtype",
//       key: "eventtype",
//       ellipsis: true,
//     },
//     {
//       title: "Webinar Id",
//       dataIndex: "webinarid",
//       key: "webinarid",
//       ellipsis: true,
//     },
//     {
//       title: "Template",
//       dataIndex: "template",
//       key: "template",
//       ellipsis: true,
//     },
//     {
//       title: "WaGroupLink",
//       dataIndex: "wagrouplink",
//       key: "wagrouplink",
//       ellipsis: true,
//     },
//     {
//       title: "web. reg. Link",
//       dataIndex: "webinarregistrationlink",
//       key: "webinarregistrationlink",
//       ellipsis: true,
//     },
//     {
//       title: "STATUS",
//       key: "status",
//       width: 150,
//       // render: (text, record) => {
//       //   // Check if the current webinar is ongoing or upcoming
//       //   const isOngoing = new Date(record.webinardate) < new Date();
//       //   return isOngoing ? <span>Ongoing</span> : <span>Upcoming</span>;
//       // },
//       render: (text, record) => {
//         // Check if the current webinar is ongoing or upcoming
//         const isOngoing = new Date(record.webinardate) < new Date();
//         const statusColor = isOngoing ? "green" : "blue";
//         return (
//           <span style={{ color: statusColor }}>
//             {isOngoing ? "Ongoing" : "Upcoming"}
//           </span>
//         );
//       },
//     },
//     {
//       title: "ACTION",
//       key: "action",
//       width: 150,

//       render: (text, record) => (
//         <Space size="middle">
//           <button
//             type="button"
//             data-bs-toggle="modal"
//             data-bs-target="#exampleModal"
//             className="edit-btn"
//             onClick={() => handleEditFutureWebinar(record._id)}
//           >
//             <i className="ri-pencil-fill"></i>
//           </button>
//           <button
//             onClick={() => handleDeleteFutureWebinar(record._id)}
//             className="delete-btn"
//           >
//             <i className="ri-delete-bin-fill"></i>
//           </button>
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <div className="page-content w-100">
//       <div className="container">
//         <div className="row">
//           <div className="col-12">
//             <div className="page-title-box d-sm-flex align-items-center justify-content-between">
//               <h4 className="mb-sm-0 py-3">Current Webinar</h4>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-xxl-12">
//             <div id="contactList" className="card">
//               <div className="card-header py-3">
//                 <div className="d-flex align-items-center flex-wrap gap-2">
//                   <div className="d-flex flex-wrap flex-grow-1 gap-2">
//                     {/* <!-- Button trigger modal --> */}
//                     <button
//                       type="button"
//                       className="btn btn-primary add-btn"
//                       onClick={handleAddFutureWebinarButton}
//                       data-bs-toggle="modal"
//                       data-bs-target="#exampleModal"
//                     >
//                       <i className="ri-add-fill me-1 align-bottom"></i> Future
//                       Webinar
//                     </button>
//                     {/* Add button */}
//                     {/* <!-- Modal --> */}
//                     <div
//                       className="modal fade"
//                       id="exampleModal"
//                       tabindex="-1"
//                       aria-labelledby="exampleModalLabel"
//                       // aria-hidden={!isModalOpen}
//                       // style={{ display: isModalOpen ? "block" : "none" }}
//                     >
//                       <div className="modal-dialog">
//                         <div className="modal-content">
//                           <div className="modal-header">
//                             <h5 className="modal-title" id="exampleModalLabel">
//                               {editFutureWebinarId
//                                 ? "Update Future Webinar"
//                                 : "Add Future Webinar"}
//                             </h5>
//                             <button
//                               type="button"
//                               className="btn-close"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                             ></button>
//                           </div>
//                           <div className="modal-body">
//                             <Box>
//                               <TextField
//                                 color="primary"
//                                 fullWidth
//                                 label="Program Name"
//                                 name="programname"
//                                 value={futurewebinar.programname}
//                                 onChange={handleChangeFutureWebinar}
//                                 margin="normal"
//                                 autoFocus
//                               />
//                               {/* <TextField
//                                 color="primary"
//                                 fullWidth
//                                 label="Webinar Date"
//                                 name="webinardate"
//                                 value={futurewebinar.webinardate}
//                                 onChange={handleChangeFutureWebinar}
//                                 margin="normal"
//                                 autoFocus
//                               /> */}
//                               <input
//                                 type="datetime-local"
//                                 id="webinardate"
//                                 name="webinardate"
//                                 value={futurewebinar.webinardate}
//                                 onChange={(e) =>
//                                   handleDatePickerChange(e.target.value)
//                                 }
//                                 className="form-select w-100"
//                               />
//                               <TextField
//                                 color="primary"
//                                 fullWidth
//                                 label="Event Type"
//                                 name="eventtype"
//                                 value={futurewebinar.eventtype}
//                                 onChange={handleChangeFutureWebinar}
//                                 margin="normal"
//                                 autoFocus
//                               />
//                               <TextField
//                                 color="primary"
//                                 fullWidth
//                                 label="Webinar Id"
//                                 name="webinarid"
//                                 value={futurewebinar.webinarid}
//                                 onChange={handleChangeFutureWebinar}
//                                 margin="normal"
//                                 autoFocus
//                               />
//                               <TextField
//                                 color="primary"
//                                 fullWidth
//                                 label="Template"
//                                 name="template"
//                                 value={futurewebinar.template}
//                                 onChange={handleChangeFutureWebinar}
//                                 margin="normal"
//                                 autoFocus
//                               />
//                               <TextField
//                                 color="primary"
//                                 fullWidth
//                                 label="Wagroup link"
//                                 name="wagrouplink"
//                                 value={futurewebinar.wagrouplink}
//                                 onChange={handleChangeFutureWebinar}
//                                 margin="normal"
//                                 autoFocus
//                               />
//                               <TextField
//                                 color="primary"
//                                 fullWidth
//                                 label="webinar registration link"
//                                 name="webinarregistrationlink"
//                                 value={futurewebinar.webinarregistrationlink}
//                                 onChange={handleChangeFutureWebinar}
//                                 margin="normal"
//                                 autoFocus
//                               />
//                               <div>
//                                 {customFields.map((field, index) => (
//                                   <div key={index}>
//                                     <Grid
//                                       container
//                                       spacing={1}
//                                       style={{
//                                         display: "flex",
//                                         alignItems: "center",
//                                         justifyContent: "center",
//                                       }}
//                                     >
//                                       <Grid item xs={11}>
//                                         <TextField
//                                           size="small"
//                                           fullWidth
//                                           label={`${field.title}`}
//                                           type="text"
//                                           margin="normal"
//                                           value={field.value}
//                                           onChange={(e) =>
//                                             handleCustomFieldValueChange(
//                                               index,
//                                               e
//                                             )
//                                           }
//                                         />
//                                       </Grid>
//                                       <Grid item xs={1}>
//                                         <MinusCircleOutlined
//                                           style={{
//                                             fontSize: "25px",
//                                             textAlign: "center",
//                                           }}
//                                           onClick={() =>
//                                             handleRemoveCustomField(index)
//                                           }
//                                         />
//                                       </Grid>
//                                     </Grid>
//                                   </div>
//                                 ))}

//                                 <Grid
//                                   container
//                                   spacing={1}
//                                   style={{
//                                     display: "flex",
//                                     alignItems: "center",
//                                     marginTop: "10px",
//                                     marginBottom: "20px",
//                                   }}
//                                 >
//                                   <Grid item xs={9}>
//                                     <select
//                                       className="form-select"
//                                       margin="normal"
//                                       value={nextCustomFieldType}
//                                       onChange={(e) =>
//                                         setNextCustomFieldType(e.target.value)
//                                       }
//                                     >
//                                       <option value="">
//                                         Select a Custom Field
//                                       </option>
//                                       {customsData.map((custom) => (
//                                         <option
//                                           key={custom._id}
//                                           value={custom.title}
//                                         >
//                                           {custom.title}
//                                         </option>
//                                       ))}
//                                     </select>
//                                   </Grid>
//                                   <Grid item xs={2}>
//                                     <Button
//                                       style={{
//                                         display: "flex",
//                                         alignItems: "center",
//                                         justifyContent: "center",
//                                       }}
//                                       type="dashed"
//                                       onClick={handleAddCustomField}
//                                       icon={<PlusOutlined />}
//                                     >
//                                       Add field
//                                     </Button>
//                                   </Grid>
//                                 </Grid>
//                               </div>
//                             </Box>
//                           </div>
//                           <div className="modal-footer">
//                             <button
//                               onClick={handleAddFutureWebinar}
//                               type="button"
//                               className="btn btn-primary"
//                               data-bs-dismiss="modal"
//                             >
//                               {editFutureWebinarId ? "Update" : "Save"}
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex-shrink-0"></div>
//                 </div>
//                 <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
//                   <div className="flex-shrink-0">
//                     <div className="hstack text-nowrap gap-2 align-item-center"></div>
//                   </div>
//                 </div>
//               </div>
//               <div className="card-body">
//                 <Table
//                   dataSource={currentWebinars}
//                   columns={columns}
//                   rowKey="_id"
//                   scroll={{ x: true }}
//                   pagination={{
//                     pageSize: 5,
//                   }}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CurrentWebinar;
