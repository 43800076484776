import React, { useState, useEffect } from "react";
import "../styles/status.css";
import { NavLink, Outlet, useParams } from "react-router-dom";
import axios from "axios";
import { Spin } from "antd";

const Status = () => {
  const [contactDetails, setContactDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { contactId } = useParams();
  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await axios.get(
          `/api/contact/getcontact/${contactId}`
        );
        setContactDetails(response.data);
      } catch (error) {
        console.error("Error fetching contact details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContactDetails();
  }, [contactId]);

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">
                {/* <i className="ri-hashtag"></i>  */}
                {contactDetails.name}
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div className="status_main">
              <div className="status_submain">
                <div class="card status_profile">
                  {/* <div class="card-header card_head">Primary Contact</div> */}
                  <div class="card-body">
                    <h5>Primary Contact</h5>
                    {loading ? (
                      <div className="loading-spinner">
                        <Spin size="large" /> {/* Display loading spinner */}
                      </div>
                    ) : (
                      <div style={{ color: "#979797" }}>
                        <div className="user_details">
                          <span>
                            <i class="ri-user-line"></i>
                          </span>
                          <p>Name: {contactDetails.name}</p>
                        </div>
                        <div className="user_details">
                          <span>
                            <i class="ri-mail-line"></i>
                          </span>
                          <p>Email: {contactDetails.email}</p>
                        </div>
                        <div className="user_details mb-4">
                          <span>
                            <i class="ri-phone-line"></i>
                          </span>
                          <p>Mobile: {contactDetails.mobile}</p>
                        </div>
                      </div>
                    )}
                    <h5>Leads</h5>
                    <div style={{ color: "#979797" }} className="mb-4">
                      {contactDetails.leadscore && (
                        <div style={{ color: "#979797" }}>
                          {contactDetails.leadscore.map((score, index) => (
                            <div className="user_details" key={index}>
                              {score.read !== undefined && (
                                <p className="read-score">Read: {score.read}</p>
                              )}
                              {score.delivered !== undefined && (
                                <p className="delivered-score">
                                  Delivered: {score.delivered}
                                </p>
                              )}
                              {score.failed !== undefined && (
                                <p className="failed-score">
                                  Failed: {score.failed}
                                </p>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card status_table">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <NavLink
                        to={`/dashboard/status/${contactId}/timeline`}
                        className="nav-link"
                        activeclassname="active"
                      >
                        Timeline
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink
                        to={`/dashboard/status/${contactId}/activities`}
                        className="nav-link"
                        activeclassname="active"
                      >
                        Activites
                      </NavLink>
                    </li> */}
                    <li className="nav-item">
                      <NavLink
                        to={`/dashboard/status/${contactId}/notes`}
                        className="nav-link"
                        activeclassname="active"
                      >
                        Notes
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink
                        to={`/dashboard/status/${contactId}/invoices`}
                        className="nav-link"
                        activeclassname="active"
                      >
                        Invoices
                      </NavLink>
                    </li> */}
                    <li className="nav-item">
                      <NavLink
                        to={`/dashboard/status/${contactId}/courses`}
                        className="nav-link"
                        activeclassname="active"
                      >
                        Courses
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink
                        to={`/dashboard/status/${contactId}/payment`}
                        className="nav-link"
                        activeclassname="active"
                      >
                        Payment
                      </NavLink>
                    </li> */}
                    {/* <li className="nav-item">
                      <NavLink
                        to={`/dashboard/status/${contactId}/emails`}
                        className="nav-link"
                        activeclassname="active"
                      >
                        Emails
                      </NavLink>
                    </li> */}

                    <li className="nav-item">
                      <NavLink
                        to={`/dashboard/status/${contactId}/personalinformation`}
                        className="nav-link"
                        activeclassname="active"
                      >
                        Personal Information
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to={`/dashboard/status/${contactId}/messagestatus`}
                        className="nav-link"
                        activeclassname="active"
                      >
                        Message Status
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status;
