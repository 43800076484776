import React, { useEffect, useState } from "react";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import Swal from "sweetalert2";
import { Space, Table } from "antd";

const Schedule = () => {
  const [data, setData] = useState([]);
  const [editReportsId, setEditReportsId] = useState(null);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [options, setOptions] = useState([]);

  const [messageTemplate, setMessageTemplate] = useState("");
  const [templatedata, setTemplateData] = useState([]);

  const fetchReports = async () => {
    try {
      const response = await axios.get("/api/schedule/getall");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  useEffect(() => {
    const getsegmentsdata = async () => {
      const getsegmentsname = [];

      try {
        const response = await axios.get("/api/segment/get");

        for (let i = 0; i < response.data.length; i++) {
          getsegmentsname.push(response.data[i].title);
        }

        setOptions(getsegmentsname);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchTemplateData = async () => {
      try {
        const response = await axios.get("/api/template/getalltemplate");
        setTemplateData(response.data);
      } catch (error) {
        console.error("Error fetching customs data:", error);
      }
    };

    getsegmentsdata();
    fetchTemplateData();
  }, []);

  const handleChangeSegments = (selectedSegments) => {
    setSelectedSegments(selectedSegments);
  };

  const [formData, setFormData] = useState({
    scheduledDateTime: "",
    receiverMobileNos: [],
    message: "",
  });

  const handleDatePickerChange = (dateTimeString) => {
    setFormData({ ...formData, scheduledDateTime: dateTimeString });
  };

  const handleSubmitSchedule = async (e) => {
    e.preventDefault();

    const finalformData = {
      ...formData,
      message: messageTemplate,
      receiverMobileNos: selectedSegments,
    };

    if (editReportsId) {
      axios
        .put(`/api/schedule/edit/${editReportsId}`, finalformData)
        .then((res) => {
          setEditReportsId(null);
          setFormData({
            scheduledDateTime: "",
            receiverMobileNos: [],
            message: "",
          });
          setMessageTemplate("");
          setSelectedSegments([]);
          Swal.fire({
            title: "Schedule Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchReports();
          });
        })
        .catch((error) => {
          Swal.fire("Error", "Error creating Tags. Please try again.", "error");
        });
    } else {
      axios
        .post("/api/schedule/create", finalformData)
        .then((res) => {
          setEditReportsId(null);
          setFormData({
            scheduledDateTime: "",
            receiverMobileNos: [],
            message: "",
          });
          setMessageTemplate("");
          setSelectedSegments([]);
          Swal.fire({
            title: "Schedule Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchReports();
          });
        })
        .catch((error) => {
          Swal.fire("Error", "Error creating Tags. Please try again.", "error");
        });
    }
  };

  const handleEditReports = (_id) => {
    const selectedReports = data.find((reportid) => reportid._id === _id);

    if (selectedReports) {
      setEditReportsId(_id);
      setFormData({
        scheduledDateTime: selectedReports.scheduledDateTime,
        receiverMobileNos: selectedReports.receiverMobileNos,
        message: selectedReports.message,
      });
      setMessageTemplate(selectedReports.message); // Assuming `message` is the correct property
      setSelectedSegments(selectedReports.receiverMobileNos);
    }
    // setIsModalOpen(true);
  };

  const handleDeleteReports = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/schedule/delete/${_id}`)
        .then((res) => {
          fetchReports();
          Swal.fire("Deleted!", "Report deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },

    {
      title: "TIME",
      dataIndex: "scheduledDateTime",
      key: "scheduledDateTime",
      ellipsis: true,
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      key: "message",
      ellipsis: true,

      render: (textItem) => (
        <div style={{ whiteSpace: "pre-line" }}>
          {textItem ? textItem.match(/.{1,50}/g).join("\n") : "NA"}
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      render: (status) => {
        let color;
        switch (status) {
          case "success":
            color = "green";
            break;
          case "pending":
            color = "yellow";
            break;
          case "fail":
            color = "red";
            break;
          default:
            color = "black";
            break;
        }

        return <span style={{ color }}>{status}</span>;
      },
    },

    {
      title: "ACTION",
      key: "action",
      width: 150,

      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            className="edit-btn"
            onClick={() => handleEditReports(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>
          <button
            onClick={() => handleDeleteReports(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Schedule</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-body">
                <div className="schedule">
                  <div>
                    <form>
                      <div className="main_form_red">
                        <div className="main_blue">
                          <div className="main_black">
                            <div className="black_child">
                              <label htmlFor="">Date</label>
                            </div>
                            <span>:</span>
                            <div className="black_child">
                              <input
                                type="datetime-local"
                                id="time"
                                name="time"
                                value={formData.scheduledDateTime}
                                onChange={(e) =>
                                  handleDatePickerChange(e.target.value)
                                }
                                className="form-select w-100"
                              />
                            </div>
                          </div>
                          <div className="main_black">
                            <div className="black_child">
                              <label htmlFor="">Message</label>
                            </div>
                            <span>:</span>
                            <div className="black_child">
                              <select
                                className="form-select w-100"
                                margin="normal"
                                value={messageTemplate}
                                onChange={(e) =>
                                  setMessageTemplate(e.target.value)
                                }
                              >
                                <option value="" disabled>
                                  --Select Template--
                                </option>
                                {templatedata.map((custom) => (
                                  <option
                                    key={custom._id}
                                    value={custom.textItem}
                                  >
                                    {custom.templateName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="main_black">
                            <div className="black_child">
                              <label htmlFor="">Segment</label>
                            </div>
                            <span>:</span>
                            <div className="black_child">
                              <Multiselect
                                isObject={false}
                                onSelect={(selectedList) =>
                                  handleChangeSegments(selectedList)
                                }
                                onRemove={(selectedList) =>
                                  handleChangeSegments(selectedList)
                                }
                                options={options}
                                selectedValues={selectedSegments}
                                showCheckbox
                                className="w-100"
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              className="btn btn-primary w-50 mt-4"
                              type="submit"
                              onClick={handleSubmitSchedule}
                            >
                              {editReportsId ? "Update" : "Schedule"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0 py-3">Reports</h4>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  // style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }} // Show scrollbar only if data is lengthy
                  pagination={{
                    pageSize: 5,
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
