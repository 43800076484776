import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";

// axios.defaults.baseURL = "http://localhost:7000"; // Update this to match your server's URL
// axios.defaults.baseURL = "https://crm.autowapsender.com"; // Update this to match your server's URL
axios.defaults.baseURL = "https://tmc.custwiz.com";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
