import React, { useState, useEffect } from "react";
import "../styles/timeline.css";
import { Timeline as AntTimeline } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const Courses = () => {
  const [data, setData] = useState([]);
  const [editCourseId, setEditCourseId] = useState(null);
  const { contactId } = useParams();

  const [course, setCourse] = useState({
    coursename: "",
  });

  const fetchCourseData = async () => {
    try {
      const response = await axios.get(`/api/course/${contactId}/getcourse`);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, [contactId]);

  //console.log(data);

  const handleChangeCourse = (e) => {
    const { name, value } = e.target;
    setCourse({
      ...course,
      [name]: value,
    });
  };

  const handleEditCourse = (_id) => {
    const selectedCourse = data.find((courseid) => courseid._id === _id);

    if (selectedCourse) {
      setEditCourseId(_id); // Set the ID of the contact being edited
      setCourse({
        coursename: selectedCourse.coursename,
      });
    }
  };

  const handleDeleteCourse = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/course/${contactId}/deletecourse/${_id}`)
        .then((res) => {
          fetchCourseData();
          Swal.fire("Deleted!", "Course deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getCourseData = async () => {
      try {
        const response = await axios.get("/api/allcourses/getcourses");
        const responseData = response.data;

        const getCourseName = responseData.map((course) => course.coursename);

        setOptions(getCourseName);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getCourseData();
  }, []);

  const handleSaveCourse = (e) => {
    e.preventDefault();

    const courseData = {
      ...course,
    };

    if (editCourseId) {
      axios
        .put(`/api/course/${contactId}/editcourse/${editCourseId}`, courseData)
        .then((res) => {
          setCourse({ coursename: "" });
          setEditCourseId(null);
          Swal.fire({
            title: "Course Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchCourseData();
          });
        })
        .catch((error) => {
          console.error("Error creating Course:", error);
          Swal.fire(
            "Error",
            "Error creating Course. Please try again.",
            "error"
          );
        });
    } else {
      axios
        .post(`/api/course/${contactId}/create`, courseData)
        .then((res) => {
          setCourse({ coursename: "" });
          setEditCourseId(null);
          Swal.fire({
            title: "Course Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchCourseData();
          });
        })
        .catch((error) => {
          console.error("Error creating Course:", error);
          Swal.fire(
            "Error",
            "Error creating Course. Please try again.",
            "error"
          );
        });
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
        <div className="col-sm-9">
          {/* <input
            type="text"
            name="title"
            value={course.title}
            onChange={handleChangeCourse}
            placeholder="Add Course"
            className="form-control"
          /> */}
          <select
            name="coursename"
            value={course.coursename}
            onChange={handleChangeCourse}
            className="form-control form-select"
          >
            <option value="" disabled>
              Select a Course
            </option>
            {options.map((courseOption, index) => (
              <option key={index} value={courseOption}>
                {courseOption}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-primary add-btn"
            onClick={handleSaveCourse}
          >
            <i className="ri-add-fill me-1 align-bottom"></i>
            {editCourseId ? "Update Course" : "Add Course"}
          </button>
        </div>
      </div>

      <div className="timeline-tracking">
        <AntTimeline>
          {data.map((courseItem) => (
            <AntTimeline.Item key={courseItem.id}>
              <div className="timeline-title">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h6>{courseItem.coursename} </h6>
                  </div>
                  {/* <div>
                    <span>
                      {new Date(courseItem.createdOn).toLocaleString()}
                    </span>
                  </div> */}
                </div>
                {/* <span>{courseItem.description}</span> */}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div></div>
                <div style={{ display: "flex", gap: "10px", fontSize: "18px" }}>
                  <div>
                    <button
                      type="button"
                      onClick={() => handleEditCourse(courseItem._id)}
                      className="timeline-edit-btn"
                    >
                      <i className="ri-pencil-fill"></i>
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => handleDeleteCourse(courseItem._id)}
                      className="timeline-delete-btn"
                    >
                      <i className="ri-delete-bin-fill"></i>
                    </button>
                  </div>
                </div>
              </div>
            </AntTimeline.Item>
          ))}
        </AntTimeline>
      </div>
    </>
  );
};

export default Courses;
