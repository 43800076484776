import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table } from "antd";
import { Box, TextField } from "@mui/material";
import qs from "qs";

const SuppressionList = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [suppression, setSuppression] = useState({
    mobile: "",
    email: "",
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [total, setTotal] = useState(0);
  const [selectedOption, setSelectedOption] = useState("Mobile"); // Tracks the selected dropdown option
  const [inputValue, setInputValue] = useState(""); // Tracks input field value

  const fetchSuppressionData = async () => {
    try {
      const params = {
        page: tableParams.pagination.current,
        limit: tableParams.pagination.pageSize,
      };

      const response = await axios.get(
        `/api/suppression/get?${qs.stringify(params)}`
      );
      setData(response.data.contacts);
      setTotal(response.data.totalContacts);
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: response.data.totalContacts,
        },
      }));
    } catch (error) {
      console.error("Error fetching suppression data:", error);
    }
  };

  useEffect(() => {
    fetchSuppressionData();
  }, [tableParams.pagination.current, tableParams.pagination.pageSize]);

  const handleChangeSuppression = (e) => {
    const { name, value } = e.target;
    setSuppression({
      ...suppression,
      [name]: value,
    });
  };

  const handleAddSuppression = (e) => {
    e.preventDefault();
    const suppressionData = { ...suppression };
    axios
      .post("/api/suppression/add", suppressionData)
      .then(() => {
        setSuppression({ mobile: "" });
        Swal.fire({
          title: "Contact moved to Suppression List and Deleted from Contacts.",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Saved",
        }).then(() => {
          fetchSuppressionData();
        });
      })
      .catch(() => {
        Swal.fire(
          "Error",
          "Error creating Suppression List. Please try again.",
          "error"
        );
      });
  };

  const handleAddSuppressionButton = () => {
    setSuppression({ mobile: "", email: "" });
    setIsModalOpen(true);
  };

  const getDataSource = () => {
    return data;
  };

  const handleDeleteSuppressionList = async (_id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/suppression/delete/${_id}`)
        .then((res) => {
          fetchSuppressionData();
          Swal.fire(
            "Deleted!",
            "Suppression List deleted successfully!",
            "success"
          );
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleRecycleSuppressionList = async (_id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, Restore it!",
    });

    if (confirmed.isConfirmed) {
      Swal.fire({
        title: "Restored...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .post(`/api/suppression/move/${_id}`)
        .then((res) => {
          fetchSuppressionData();
          Swal.fire(
            "Restored!",
            "Suppression List Restored successfully!",
            "success"
          );
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      align: "center",
    },
    {
      title: "WhatsApp Number",
      dataIndex: "whatsappMobile",
      key: "whatsappMobile",
      ellipsis: true,
      align: "center",
    },

    {
      title: "ACTION",
      key: "action",
      width: 150,
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <button
            onClick={() => handleDeleteSuppressionList(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
          <button
            onClick={() => handleRecycleSuppressionList(record._id)}
            className="delete-btn"
          >
            {/* <i class="ri-recycle-line"></i> */}
            <i class="ri-recycle-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  // Handle dropdown selection
  const handleDropdownSelection = (value) => {
    setSelectedOption(value);
    setInputValue(""); // Reset input value when option changes
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Suppression List</h4>
              <div>
                <h4 className="mb-sm-0 py-3">Total Count : {total}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  {/* <button
                    type="button"
                    className="btn btn-primary add-btn"
                    onClick={handleAddSuppressionButton}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="ri-add-fill me-1 align-bottom"></i> Add
                    suppression
                  </button> */}
                  {/* Selection Option */}
                  <div>
                    <form action="" className="d-flex gap-3">
                      <div class="input-group" style={{ width: "350px" }}>
                        <button
                          class="btn btn-outline-secondary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ width: "115px" }}
                        >
                          {selectedOption} {/* Show selected option */}
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              className={`dropdown-item ${
                                selectedOption === "Mobile" ? "active" : ""
                              }`} // Highlight active choice
                              href="#"
                              onClick={() => handleDropdownSelection("Mobile")}
                            >
                              Mobile
                            </a>
                          </li>
                          <li>
                            <a
                              className={`dropdown-item ${
                                selectedOption === "Email" ? "active" : ""
                              }`} // Highlight active choice
                              href="#"
                              onClick={() => handleDropdownSelection("Email")}
                            >
                              Email
                            </a>
                          </li>
                        </ul>
                        <input
                          type="text"
                          name={selectedOption.toLowerCase()} // Dynamically set name based on option
                          value={suppression[selectedOption.toLowerCase()]} // Dynamically set value based on option
                          class="form-control"
                          onChange={handleChangeSuppression}
                          aria-label="Text input with dropdown button"
                          style={{ width: "200px" }}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={handleAddSuppression}
                        class="btn btn-primary"
                      >
                        Add
                      </button>
                    </form>
                  </div>

                  {/* <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden={!isModalOpen}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Add Suppression
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <Box>
                            <TextField
                              color="primary"
                              fullWidth
                              label="Mobile"
                              name="mobile"
                              value={suppression.mobile}
                              onChange={handleChangeSuppression}
                              margin="normal"
                              required
                              autoFocus
                            />
                          </Box>
                        </div>
                        <div className="modal-footer">
                          <button
                            onClick={handleAddSuppression}
                            type="button"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={getDataSource()}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  pagination={{
                    current: tableParams.pagination.current,
                    pageSize: tableParams.pagination.pageSize,
                    total: total,
                    onChange: (page, pageSize) => {
                      setTableParams({
                        pagination: {
                          current: page,
                          pageSize: pageSize,
                        },
                      });
                    },
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuppressionList;
