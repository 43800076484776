import React, { useState, useEffect } from "react";
import "../styles/timeline.css";
import { useParams } from "react-router-dom";
import { Form, Radio, Slider, Input, message, Typography, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { PolarArea } from "react-chartjs-2";
import Swal from "sweetalert2";
import axios from "axios";

const { Title, Text } = Typography;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const marks = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
};
marks[10] = "10";

const initialValues = {
  whatsapp: false,
  hubLoggedIn: false,
  telegramGroup: false,
  healthSlider: 5,
  moneySlider: 5,
  careerSlider: 5,
  relationshipSlider: 5,
  emotionSlider: 5,
  spiritualitySlider: 5,
  remarks: "",
};

const Personalinfo = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [savedValues, setSavedValues] = useState(null);
  const { contactId } = useParams();
  const [isPersonalInfoAdded, setIsPersonalInfoAdded] = useState(false); // New state

  const fetchPersonalinfoData = async () => {
    try {
      const response = await axios.get(
        `/api/personalinformation/${contactId}/getpersonalinformation`
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPersonalinfoData();
  }, [contactId]);

  useEffect(() => {
    // Update the isPersonalInfoAdded state based on the presence of data
    setIsPersonalInfoAdded(data.length > 0);
  }, [data]);

  const calculateTotalScores = () => {
    const totalHealth = data.reduce((sum, item) => sum + item.health, 0);
    const totalMoney = data.reduce((sum, item) => sum + item.money, 0);
    const totalCareer = data.reduce((sum, item) => sum + item.career, 0);
    const totalEmotion = data.reduce((sum, item) => sum + item.emotion, 0);
    const totalRelationship = data.reduce(
      (sum, item) => sum + item.relationship,
      0
    );
    const totalSpirituality = data.reduce(
      (sum, item) => sum + item.spirituality,
      0
    );

    return [
      totalHealth,
      totalMoney,
      totalCareer,
      totalEmotion,
      totalRelationship,
      totalSpirituality,
    ];
  };

  const prepareChartData = () => {
    const [
      totalHealth,
      totalMoney,
      totalCareer,
      totalEmotion,
      totalRelationship,
      totalSpirituality,
    ] = calculateTotalScores();

    return {
      labels: [
        "Health",
        "Money",
        "Career",
        "Emotion",
        "Relationship",
        "Spirituality",
      ],
      datasets: [
        {
          data: [
            totalHealth,
            totalMoney,
            totalCareer,
            totalEmotion,
            totalRelationship,
            totalSpirituality,
          ],

          backgroundColor: [
            "rgb(255, 99, 132)", // red
            "rgb(255, 205, 86)", // yellow
            "rgb(54, 162, 235)", // blue
            "rgb(124, 252, 0)", // green (changed from teal)
            "rgb(255, 159, 64)", // orange
            "rgb(153, 102, 255)", // purple
          ],
          hoverBackgroundColor: [
            "rgb(255, 159, 164)", // faded red
            "rgb(255, 219, 176)", // faded yellow
            "rgb(129, 191, 236)", // faded blue
            "rgb(142, 255, 112)", // faded green (changed from teal)
            "rgb(255, 184, 116)", // faded orange
            "rgb(183, 150, 255)", // faded purple
          ],

          cutoutPercentage: 60, // Set cutoutPercentage for doughnut chart
        },
      ],
    };
  };

  const handleEditPersonalInfo = (id) => {
    // Find the personal information item to edit based on its id
    const personalInfoToEdit = data.find((item) => item._id === id);

    // Set the editingId state to the id of the item being edited
    setEditingId(id);

    // Populate the form with the existing data
    form.setFieldsValue({
      whatsapp: personalInfoToEdit.whatsapp,
      hubLoggedIn: personalInfoToEdit.hubloggedin,
      telegramGroup: personalInfoToEdit.telegramgroup,
      healthSlider: personalInfoToEdit.health,
      moneySlider: personalInfoToEdit.money,
      careerSlider: personalInfoToEdit.career,
      relationshipSlider: personalInfoToEdit.relationship,
      emotionSlider: personalInfoToEdit.emotion,
      spiritualitySlider: personalInfoToEdit.spirituality,
      remarks: personalInfoToEdit.remark,
    });

    // Show the modal for editing
    // You may need to adjust the modal visibility state in your component
    // depending on how your modal is managed.
    // For simplicity, I'm assuming a state variable named `isEditing`.
    // You should adjust this based on your actual implementation.
    setIsEditing(true);
  };

  // console.log(isEditing);
  // console.log(editingId);

  const onFinishEdit = async (values) => {
    try {
      const mappedData = {
        whatsapp: values.whatsapp,
        hubloggedin: values.hubLoggedIn,
        telegramgroup: values.telegramGroup,
        health: values.healthSlider,
        money: values.moneySlider,
        career: values.careerSlider,
        relationship: values.relationshipSlider,
        emotion: values.emotionSlider,
        spirituality: values.spiritualitySlider,
        remark: values.remarks,
      };

      // Update the existing personal information item with the edited data
      await axios.put(
        `/api/personalinformation/${contactId}/updatepersonal/${editingId}`,
        mappedData
      );

      // Reset the editingId state
      setEditingId(null);

      // Clear the form fields
      form.resetFields();

      // Close the modal for editing
      // Adjust this based on your actual modal management implementation
      setIsEditing(false);

      // Refetch the personal information data
      fetchPersonalinfoData();

      message.success("Personal information updated successfully!");
    } catch (error) {
      console.error("API Error:", error);
      message.error("Error updating personal information. Please try again.");
    }
  };

  const onFinish = async (values) => {
    // console.log(values);

    try {
      const mappedData = {
        whatsapp: values.whatsapp,
        hubloggedin: values.hubLoggedIn,
        telegramgroup: values.telegramGroup,
        health: values.healthSlider,
        money: values.moneySlider,
        career: values.careerSlider,
        relationship: values.relationshipSlider,
        emotion: values.emotionSlider,
        spirituality: values.spiritualitySlider,
        remark: values.remarks,
      };

      const response = await axios.post(
        `/api/personalinformation/${contactId}/create`,
        mappedData
      );

      setSavedValues(mappedData);
      fetchPersonalinfoData();

      message.success("Personal information saved successfully!");
    } catch (error) {
      console.error("API Error:", error);
      message.error("Error saving personal information. Please try again.");
    }
  };

  const handleSave = () => {
    form.submit();
  };

  const handleDeletePersonalInfo = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/personalinformation/${contactId}/deletepersonal/${_id}`)
        .then((res) => {
          fetchPersonalinfoData();
          Swal.fire(
            "Deleted!",
            "Personal Information deleted successfully!",
            "success"
          );
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p></p>

        {!isPersonalInfoAdded && (
          <button
            type="button"
            className="btn btn-primary add-btn"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <i className="ri-add-fill me-1 align-bottom"></i> Add Personal Info
          </button>
        )}

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {editingId ? "Update Personal Info" : "Add Personal Info"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Form
                  form={form}
                  name="validate_other"
                  {...formItemLayout}
                  // onFinish={onFinish}
                  onFinish={editingId ? onFinishEdit : onFinish}
                  initialValues={initialValues}
                  style={{
                    maxWidth: 600,
                  }}
                >
                  <Form.Item name="whatsapp" label="Whatsapp">
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="hubLoggedIn" label="HUB LoggedIn">
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="telegramGroup" label="Telegram Group">
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item name="healthSlider" label="Health (Physical)">
                    <Slider marks={marks} max={10} />
                  </Form.Item>
                  <Form.Item name="moneySlider" label="Money">
                    <Slider marks={marks} max={10} />
                  </Form.Item>
                  <Form.Item name="careerSlider" label="Career">
                    <Slider marks={marks} max={10} />
                  </Form.Item>
                  <Form.Item name="relationshipSlider" label="Relationship">
                    <Slider marks={marks} max={10} />
                  </Form.Item>
                  <Form.Item name="emotionSlider" label="E&M Wellbeing">
                    <Slider marks={marks} max={10} />
                  </Form.Item>
                  <Form.Item name="spiritualitySlider" label="Spirituality">
                    <Slider marks={marks} max={10} />
                  </Form.Item>

                  <Form.Item
                    name="remarks"
                    label="Remarks"
                    rules={[{ message: "Please input Remarks" }]}
                  >
                    <Input.TextArea showCount maxLength={100} />
                  </Form.Item>
                  {/* <div style={{ display: "flex", float: "right" }}>
                    <Button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      type="dashed"
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </div> */}
                </Form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                  data-bs-dismiss="modal"
                >
                  {editingId ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="timeline-tracking chart">
        {data.map((personalinfoItem, index) => (
          <>
            <div className="status_submain personal_submain" key={index}>
              <div className="card status_profile">
                <div className="card-body ">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h5>Personal Information</h5>

                    <div>
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() =>
                          handleEditPersonalInfo(personalinfoItem._id)
                        }
                        className="timeline-edit-btn"
                      >
                        <i className="ri-pencil-fill"></i>
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          handleDeletePersonalInfo(personalinfoItem._id)
                        }
                        className="timeline-delete-btn"
                      >
                        <i className="ri-delete-bin-fill"></i>
                      </button>
                    </div>
                  </div>

                  <div
                    style={{ color: "#212121" }}
                    className="personal_details"
                  >
                    <div className="user_personal_details">
                      <span>
                        <i class="ri-whatsapp-line"></i>
                      </span>
                      <p>
                        Whatsapp:{" "}
                        <b>{personalinfoItem.whatsapp ? "Yes" : "No"}</b>
                      </p>
                    </div>
                    <div className="user_personal_details">
                      <span>
                        <i class="ri-login-circle-line"></i>
                      </span>
                      <p>
                        HUB LoggedIn:{" "}
                        <b>{personalinfoItem.hubloggedin ? "Yes" : "No"}</b>
                      </p>
                    </div>
                    <div className="user_personal_details">
                      <span>
                        <i class="ri-telegram-line"></i>
                      </span>
                      <p>
                        Telegram Group:{" "}
                        <b>{personalinfoItem.telegramgroup ? "Yes" : "No"}</b>
                      </p>
                    </div>
                    <div className="user_personal_details">
                      <span>
                        <i class="ri-heart-pulse-line"></i>
                      </span>
                      <p>
                        Health: <b>{personalinfoItem.health}</b>
                      </p>
                    </div>
                    <div className="user_personal_details">
                      <span>
                        <i class="ri-money-dollar-circle-line"></i>
                      </span>
                      <p>
                        Money: <b>{personalinfoItem.money}</b>
                      </p>
                    </div>
                    <div className="user_personal_details">
                      <span>
                        <i class="ri-rocket-2-line"></i>
                      </span>
                      <p>
                        Career: <b>{personalinfoItem.career}</b>
                      </p>
                    </div>
                    <div className="user_personal_details">
                      <span>
                        <i class="ri-group-line"></i>
                      </span>
                      <p>
                        Relationship: <b>{personalinfoItem.relationship}</b>
                      </p>
                    </div>
                    <div className="user_personal_details">
                      <span>
                        <i class="ri-emotion-line"></i>
                      </span>
                      <p>
                        E&M Wellbeing: <b>{personalinfoItem.emotion}</b>
                      </p>
                    </div>
                    <div className="user_personal_details">
                      <span>
                        {/* <i class="ri-focus-2-line"></i> */}
                        <i class="ri-magic-line"></i>
                      </span>
                      <p>
                        Spirituality: <b>{personalinfoItem.spirituality}</b>
                      </p>
                    </div>
                    <div className="user_personal_details">
                      <span>
                        <i class="ri-booklet-line"></i>
                      </span>
                      <p>
                        Remarks: <b>{personalinfoItem.remark}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pie_chart">
              <PolarArea data={prepareChartData()} />
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default Personalinfo;
