import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table } from "antd";
import { Box, TextField, Grid } from "@mui/material";

const WebinarTag = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editWebinarTagId, setEditWebinarTagId] = useState(null);

  const [webinartag, setWebinarTag] = useState({
    prefix: "",
    content: "",
    suffix: "",
  });

  const fetchWebinarTagData = async () => {
    try {
      const response = await axios.get("/api/webinartag/get");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWebinarTagData();
  }, []);

  const handleChangeWebinarTag = (e) => {
    const { name, value } = e.target;
    setWebinarTag({
      ...webinartag,
      [name]: value,
    });
  };

  const handleAddWebinarTag = (e) => {
    e.preventDefault();

    const webinarTagData = {
      ...webinartag,
    };
    if (editWebinarTagId) {
      axios
        .put(`/api/webinartag/update/${editWebinarTagId}`, webinarTagData)
        .then((res) => {
          setWebinarTag({ prefix: "", content: "", suffix: "" });
          Swal.fire({
            title: "Webinar Tag Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchWebinarTagData();
          });
        })
        .catch((error) => {
          Swal.fire("Error", "Error creating Tag. Please try again.", "error");
        });
    } else {
      axios
        .post("/api/webinartag/create", webinarTagData)
        .then((res) => {
          setWebinarTag({ prefix: "", content: "", suffix: "" });
          Swal.fire({
            title: "Webinar Tag Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchWebinarTagData();
          });
        })
        .catch((error) => {
          Swal.fire("Error", "Error creating Tag. Please try again.", "error");
        });
    }
  };

  const handleDeleteWebinarTag = async (_id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/webinartag/delete/${_id}`)
        .then((res) => {
          fetchWebinarTagData();
          Swal.fire("Deleted!", "Webinar Tag deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleEditWebinarTag = (_id) => {
    const selectedWebinarTag = data.find((webtagid) => webtagid._id === _id);

    if (selectedWebinarTag) {
      setEditWebinarTagId(_id);
      setWebinarTag({
        prefix: selectedWebinarTag.prefix,
        content: selectedWebinarTag.content,
        suffix: selectedWebinarTag.suffix,
      });
    }
    setIsModalOpen(true);
  };

  const handleAddWebinarTagButton = () => {
    setEditWebinarTagId(null);
    setWebinarTag({ prefix: "", content: "", suffix: "" });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const searchContacts = () => {
    return data.filter((contact) => {
      return (
        contact.prefix &&
        contact.prefix.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  const getDataSource = () => {
    if (searchQuery) {
      return searchContacts();
    } else {
      return data;
    }
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
      align: "center",
    },
    {
      title: "Prefix",
      dataIndex: "prefix",
      key: "prefix",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      ellipsis: true,
      align: "center",
    },
    {
      title: "Suffix",
      dataIndex: "suffix",
      key: "suffix",
      ellipsis: true,
      align: "center",
    },
    {
      title: "ACTION",
      key: "action",
      width: 150,
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditWebinarTag(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>
          <button
            onClick={() => handleDeleteWebinarTag(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Webinar Tag</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleAddWebinarTagButton}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add
                      Webinar Tag
                    </button>
                    <form
                      className="d-flex"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        className="form-control me-2"
                        type="search"
                        id="search"
                        placeholder="Search"
                        aria-label="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </form>
                    {/* Webinar Tag Modal */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                      style={{ display: isModalOpen ? "block" : "none" }}
                    >
                      <div
                        className="modal-dialog modal-lg modal-dialog-centered"
                        style={{ maxWidth: "62%" }}
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editWebinarTagId
                                ? "Update Webinar Tag"
                                : "Add Webinar Tag"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              <Grid
                                container
                                spacing={1}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid item xs={4}>
                                  <h6>Prefix</h6>
                                  <select
                                    className="form-select mt-2"
                                    aria-label="Default select example"
                                    name="prefix"
                                    value={webinartag.prefix}
                                    onChange={handleChangeWebinarTag}
                                  >
                                    <option value="ddmmyy" name="ddmmyy">
                                      ddmmyy
                                    </option>
                                    <option value="ddmmmyyyy" name="ddmmmyyyy">
                                      ddmmmyyyy
                                    </option>
                                    <option
                                      value="ddmmmmyyyy"
                                      name="ddmmmmyyyy"
                                    >
                                      ddmmmmyyyy
                                    </option>
                                    <option
                                      value="ddmmyy-hh:mm"
                                      name="ddmmyy-hh:mm"
                                    >
                                      ddmmyy-hh:mm
                                    </option>
                                    <option
                                      value="ddmmmyyyy-hh:mm"
                                      name="ddmmmyyyy-hh:mm"
                                    >
                                      ddmmmyyyy-hh:mm
                                    </option>
                                    <option
                                      value="ddmmmmyyyy-hh:mm"
                                      name="ddmmmmyyyy-hh:mm"
                                    >
                                      ddmmmmyyyy-hh:mm
                                    </option>
                                    <option selected value="">
                                      No prefix
                                    </option>
                                  </select>
                                </Grid>
                                <Grid item xs={4}>
                                  <h6>Content</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    label="Content"
                                    name="content"
                                    value={webinartag.content}
                                    onChange={handleChangeWebinarTag}
                                    // margin="normal"
                                    autoFocus
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <h6>Suffix</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    label="Suffix"
                                    name="suffix"
                                    value={webinartag.suffix}
                                    onChange={handleChangeWebinarTag}
                                    // margin="normal"
                                    autoFocus
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              onClick={handleAddWebinarTag}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {editWebinarTagId ? "Update" : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={getDataSource()}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }}
                  pagination={{
                    pageSize: 5,
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarTag;
