import React from "react";

const Invoices = () => {
  return (
    <div>
      <h5 className="card-title">Invoices</h5>
      <p className="card-text">
        With supporting text below as a natural lead-in to additional content.
      </p>
      <a href="/" className="btn btn-primary">
        Go somewhere
      </a>
    </div>
  );
};

export default Invoices;
