import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = "6LeBp-8oAAAAAPhUdFOYlKFy3aVQec7J8zelVfaA";

const SignUp = () => {
  const [captchaValue, setCaptchaValue] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  const navigate = useNavigate();

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setIsCaptchaValid(true); // Reset the validity on each captcha change
  };

  const register = () => {
    const { firstname, lastname, email, mobile, password } = user;

    if (firstname && lastname && email && mobile && password) {
      if (!captchaValue) {
        setIsCaptchaValid(false);
        return;
      }

      axios
        .get(`/api/user/check-email/${email}`)
        .then((response) => {
          if (response.data.exists) {
            // Display an error message indicating that the email is already in use
            toast.error("User with this email is already exists.");
          } else {
            // Continue with user registration if email is not in use
            axios
              .post("/api/user/register", {
                ...user,
                captchaValue,
              })
              .then((res) => {
                toast.success("Signup successfully!");
                navigate("/");
              })
              .catch((error) => {
                toast.error("Something went wrong!");
              });
          }
        })
        .catch((error) => {
          toast.error(
            "Something went wrong while checking email availability."
          );
        });
    } else {
      toast.error("Please fill in all the fields.");
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#25D366" }} style={{ marginTop: "6%" }}>
            <WhatsAppIcon />
          </Avatar>
        </Box>
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            padding: "30px",
          }}
        >
          <Typography component="h1" variant="h6" style={{ color: "#1976d2" }}>
            Sign Up
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            style={{ color: "gray", fontSize: "14px" }}
          >
            Create New Account
          </Typography>

          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  size="small"
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="firstname"
                  value={user.firstname}
                  onChange={handleChange}
                  autoComplete="firstname"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  value={user.lastname}
                  onChange={handleChange}
                  autoComplete="lastname"
                  autoFocus
                />
              </Grid>
            </Grid>

            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              id="mobile"
              label="Mobile"
              name="mobile"
              value={user.mobile}
              onChange={handleChange}
              autoComplete="mobile"
              autoFocus
            />
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={user.email}
              onChange={handleChange}
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              name="password"
              value={user.password}
              onChange={handleChange}
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <ReCAPTCHA sitekey={SITE_KEY} onChange={handleCaptchaChange} />

            {!isCaptchaValid && (
              <Typography variant="body2" color="error">
                Invalid Captcha
              </Typography>
            )}

            <Button
              onClick={register}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>

            <Grid container style={{ textAlign: "center" }}>
              <Grid item xs>
                <Link
                  href="/"
                  variant="body2"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Already have an account?
                  <span style={{ color: "#1976d2" }}> SignIn</span>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignUp;
