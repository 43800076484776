import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Button, Checkbox, Form, Input } from "antd";
import Activation from "./Activation";

const Authorization = () => {
  const [data, setData] = useState([]);
  const [apikeydata, setApikeyData] = useState([]);

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const [apikeys, setApikeys] = useState({
    apikey: "",
  });

  const handleChangeCredentials = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleChangeApikey = (e) => {
    const { name, value } = e.target;
    setApikeys({
      ...apikeys,
      [name]: value,
    });
  };

  const fetchCredentialsData = async () => {
    try {
      const response = await axios.get("/api/template/credentials/get");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApikeyData = async () => {
    try {
      const response = await axios.get("/api/template/apikey/get");
      setApikeyData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCredentialsData();
    fetchApikeyData();
  }, []);

  const handleAddCredentials = (e) => {
    e.preventDefault();

    // Check if username or password is blank
    if (!credentials.username.trim() || !credentials.password.trim()) {
      Swal.fire("Error", "Username and Password cannot be blank.", "error");
      return;
    }

    // Check if credentials already exist
    if (data.length > 0) {
      Swal.fire(
        "Sorry",
        "You can not add more than one set of credentials.",
        "error"
      );
      setCredentials({
        username: "",
        password: "",
      });
      return;
    }

    const credentialData = {
      ...credentials,
    };

    axios
      .post("/api/template/credentials/add", credentialData)
      .then((res) => {
        setCredentials({
          username: "",
          password: "",
        });

        Swal.fire({
          title: "Credentials Added successfully!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Saved",
        }).then(() => {
          fetchCredentialsData();
        });
      })
      .catch((error) => {
        Swal.fire(
          "Error",
          "Error creating Credentials. Please try again.",
          "error"
        );
      });
  };

  const handleAddApikey = (e) => {
    e.preventDefault();

    // Check if username or password is blank
    if (!apikeys.apikey.trim()) {
      Swal.fire("Error", "API Key cannot be blank.", "error");
      return;
    }

    if (apikeydata.length > 0) {
      Swal.fire(
        "Sorry",
        "You can not add more than one set of ApiKey.",
        "error"
      );
      setApikeys({
        apikey: "",
      });
      return;
    }

    const apiData = {
      ...apikeys,
    };

    axios
      .post("/api/template/apikey/add", apiData)
      .then((res) => {
        setApikeys({
          apikey: "",
        });

        Swal.fire({
          title: "ApiKey Added successfully!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Saved",
        }).then(() => {
          fetchApikeyData();
        });
      })
      .catch((error) => {
        Swal.fire(
          "Error",
          "Error creating Credentials. Please try again.",
          "error"
        );
      });
  };

  const handleDeleteCredentials = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // Delete contact with the given ID
      axios
        .delete(`/api/template/credentials/delete/${_id}`)
        .then((res) => {
          // toast.success("Custom Field deleted successfully!");
          fetchCredentialsData();
          Swal.fire("Deleted!", "Credentials deleted successfully!", "success");
        })
        .catch((error) => {
          // toast.error("Something went wrong!");
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleDeleteApikey = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // Delete contact with the given ID
      axios
        .delete(`/api/template/apikey/delete/${_id}`)
        .then((res) => {
          // toast.success("Custom Field deleted successfully!");
          fetchApikeyData();
          Swal.fire("Deleted!", "API Key deleted successfully!", "success");
        })
        .catch((error) => {
          // toast.error("Something went wrong!");
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const onFinish = (values) => {
    console.log("Success");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed");
  };

  const [basicAuthFormVisible, setBasicAuthFormVisible] = useState(true);
  const [apiAuthFormVisible, setApiAuthFormVisible] = useState(false);
  const [basicAuthChecked, setBasicAuthChecked] = useState(true);
  const [apiAuthChecked, setApiAuthChecked] = useState(false);

  // Add a state variable to track whether the password should be visible or not
  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle the visibility of the password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h3 className="mb-sm-0 py-3">Authorization</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div className="main_auth">
              <div className="base_authorization">
                {/* Forms */}
                <div className="basic_authorization">
                  <div className="checkbox">
                    <div className="checkbox-item">
                      <Checkbox
                        checked={basicAuthChecked}
                        onChange={(e) => {
                          setBasicAuthChecked(e.target.checked);
                          setApiAuthChecked(false); // Uncheck the API Authorization checkbox
                          setBasicAuthFormVisible(e.target.checked);
                          setApiAuthFormVisible(false); // Close the API Authorization form
                        }}
                      >
                        Basic Authorization
                      </Checkbox>
                    </div>
                    <div className="checkbox-item">
                      <Checkbox
                        checked={apiAuthChecked}
                        onChange={(e) => {
                          setApiAuthChecked(e.target.checked);
                          setBasicAuthChecked(false); // Uncheck the Basic Authorization checkbox
                          setApiAuthFormVisible(e.target.checked);
                          setBasicAuthFormVisible(false); // Close the Basic Authorization form
                        }}
                      >
                        API Authorization
                      </Checkbox>
                    </div>
                  </div>
                  {/* Form for Credentials */}
                  {basicAuthFormVisible && (
                    <div className="main_basic">
                      <div style={{ textAlign: "center" }}>
                        <h5>
                          <span>Basic Authorization</span>
                        </h5>
                      </div>
                      <div className="credential-form">
                        <Form
                          name="basic"
                          labelCol={{
                            span: 8,
                          }}
                          wrapperCol={{
                            span: 16,
                          }}
                          style={{
                            maxWidth: 600,
                            // border: "1px solid red",
                          }}
                          initialValues={{
                            remember: true,
                          }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Form.Item
                            label="Username"
                            rules={[
                              {
                                required: true,
                                message: "Please input your username!",
                              },
                            ]}
                          >
                            <Input
                              name="username"
                              value={credentials.username}
                              onChange={handleChangeCredentials}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Password"
                            rules={[
                              {
                                required: true,
                                message: "Please input your password!",
                              },
                            ]}
                          >
                            <Input.Password
                              name="password"
                              value={credentials.password}
                              onChange={handleChangeCredentials}
                            />
                          </Form.Item>

                          <Form.Item
                            wrapperCol={{
                              offset: 8,
                              span: 16,
                            }}
                          >
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={handleAddCredentials}
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  )}

                  {/* Form for API */}

                  {apiAuthFormVisible && (
                    <div className="main_basic">
                      <div style={{ textAlign: "center" }}>
                        <h5>
                          <span>API Authorization</span>
                        </h5>
                      </div>
                      <div className="api-form">
                        <Form
                          name="basic"
                          labelCol={{
                            span: 8,
                          }}
                          wrapperCol={{
                            span: 16,
                          }}
                          style={{
                            maxWidth: 600,
                            // border: "1px solid red",
                          }}
                          initialValues={{
                            remember: true,
                          }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Form.Item
                            label="API Key"
                            name="apikey"
                            rules={[
                              {
                                required: true,
                                message: "Please input your API Key!",
                              },
                            ]}
                          >
                            <Input
                              name="apikey"
                              value={apikeys.apikey}
                              onChange={handleChangeApikey}
                            />
                          </Form.Item>

                          <Form.Item
                            wrapperCol={{
                              offset: 8,
                              span: 16,
                            }}
                          >
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={handleAddApikey}
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  )}
                </div>
                {/* Cards */}
                <div className="advance_authorization">
                  {/* Card Credentials */}

                  {data.map((record) => (
                    <div
                      key={record._id}
                      className="card p-2"
                      style={{
                        width: "14rem",
                        minHeight: "16rem",
                        position: "relative",
                      }}
                    >
                      {/* Checkbox in the top-left corner */}
                      <Activation
                        entityType="credentials"
                        entityId={record._id}
                        isActive={record.isActive}
                      />

                      <img
                        src="https://confidentialcontent.s3.eu-west-1.wasabisys.com/wateam/kz734vks.png"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h6 className="card-title">{record.username}</h6>
                        {/* <h6 className="card-text">{record.password}</h6> */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              marginRight: "8px",
                            }}
                          >
                            {showPassword ? (
                              <h6>{record.password}</h6>
                            ) : (
                              <h6>
                                {record.password.replace(/./g, "*")}
                                {/* Display password as asterisks */}
                              </h6>
                            )}
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={togglePasswordVisibility}
                            title={`Toggle password visibility`}
                          >
                            {showPassword ? (
                              <i class="ri-eye-off-line"></i>
                            ) : (
                              <i className="ri-eye-line"></i>
                            )}
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <button
                            className="btn btn-danger card-button"
                            style={{ margin: "auto" }}
                            onClick={() => handleDeleteCredentials(record._id)}
                          >
                            <i className="ri-delete-bin-6-line"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}

                  {apikeydata.map((record) => (
                    <div
                      key={record._id}
                      className="card"
                      style={{
                        width: "14rem",
                        position: "relative",
                      }}
                    >
                      {/* Checkbox in the top-left corner */}
                      <Activation
                        entityType="apikey"
                        entityId={record._id}
                        isActive={record.isActive}
                      />

                      <img
                        src="https://confidentialcontent.s3.eu-west-1.wasabisys.com/wateam/kz734vks.png"
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h6 className="card-title">API Key</h6>
                        <h6 className="card-text">{record.apikey}</h6>

                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <button
                            className="btn btn-danger card-button"
                            style={{ margin: "auto" }}
                            onClick={() => handleDeleteApikey(record._id)}
                          >
                            <i className="ri-delete-bin-6-line"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorization;
