import React, { useState, useEffect } from "react";
import "../styles/timeline.css";
import { Timeline as AntTimeline } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const Notes = () => {
  // const [activities, setActivities] = useState([]);
  const [data, setData] = useState([]);
  const [editNotesId, setEditNotesId] = useState(null);
  const { contactId } = useParams();

  const [notes, setNotes] = useState({
    title: "",
  });

  const fetchNotesData = async () => {
    try {
      const response = await axios.get(`/api/note/${contactId}/getnote`);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNotesData();
  }, [contactId]);

  const handleChangeNotes = (e) => {
    const { name, value } = e.target;
    setNotes({
      ...notes,
      [name]: value,
    });
  };

  const handleEditNotes = (_id) => {
    const selectedNotes = data.find((notesid) => notesid._id === _id);

    if (selectedNotes) {
      setEditNotesId(_id); // Set the ID of the contact being edited
      setNotes({
        title: selectedNotes.title,
      });
    }
  };

  const handleDeleteNotes = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/note/${contactId}/deletenote/${_id}`)
        .then((res) => {
          fetchNotesData();
          Swal.fire("Deleted!", "Notes deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleSaveNotes = (e) => {
    e.preventDefault();

    const notesData = {
      ...notes,
    };

    if (editNotesId) {
      axios
        .put(`/api/note/${contactId}/updatenote/${editNotesId}`, notesData)
        .then((res) => {
          setNotes({ title: "" });
          setEditNotesId(null);
          Swal.fire({
            title: "Notes Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchNotesData();
          });
        })
        .catch((error) => {
          console.error("Error creating Notes:", error);
          Swal.fire(
            "Error",
            "Error creating Notes. Please try again.",
            "error"
          );
        });
    } else {
      axios
        .post(`/api/note/${contactId}/create`, notesData)
        .then((res) => {
          setNotes({ title: "" });
          setEditNotesId(null);
          Swal.fire({
            title: "Notes Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchNotesData();
          });
        })
        .catch((error) => {
          console.error("Error creating Notes:", error);
          Swal.fire(
            "Error",
            "Error creating Notes. Please try again.",
            "error"
          );
        });
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
        <div className="col-sm-9">
          <input
            type="text"
            name="title"
            value={notes.title}
            onChange={handleChangeNotes}
            placeholder="Add Notes"
            className="form-control"
          />
        </div>
        <div>
          <button
            type="button"
            className="btn btn-primary add-btn"
            onClick={handleSaveNotes}
          >
            <i className="ri-add-fill me-1 align-bottom"></i>
            {editNotesId ? "Update Notes" : "Add Notes"}
          </button>
        </div>
      </div>

      <div className="timeline-tracking">
        <AntTimeline>
          {data.map((notesItem) => (
            <AntTimeline.Item key={notesItem.id}>
              <div className="timeline-title">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="timeline_heading">
                    <h6>{notesItem.title} </h6>
                  </div>
                  <div>
                    <span>
                      {new Date(notesItem.createdOn).toLocaleString()}
                    </span>
                  </div>
                </div>
                <span>{notesItem.description}</span>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div></div>
                <div style={{ display: "flex", gap: "10px", fontSize: "18px" }}>
                  <div>
                    <button
                      type="button"
                      onClick={() => handleEditNotes(notesItem._id)}
                      className="timeline-edit-btn"
                    >
                      <i className="ri-pencil-fill"></i>
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => handleDeleteNotes(notesItem._id)}
                      className="timeline-delete-btn"
                    >
                      <i className="ri-delete-bin-fill"></i>
                    </button>
                  </div>
                </div>
              </div>
            </AntTimeline.Item>
          ))}
        </AntTimeline>
      </div>
    </>
  );
};

export default Notes;
