import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  const [data, setData] = useState();

  const totalContacts = data ? data.length : 0;

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get("/api/contact/getcontact");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching customs data:", error);
      }
    };

    fetchContactData();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <h1>
          Welcome to the{" "}
          <span style={{ color: "#0D6EFD" }}>Mind Collective</span>
        </h1>
        <h2
          style={{
            color: "rgb(211, 32, 32)",
          }}
        >
          Dashboard
        </h2>
      </div>
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            margin: "auto",
            gap: "20px",
            padding: "20px",
          }}
        >
          <div
            className="card"
            style={{ width: "18rem", background: "#05161A", color: "#ffffff" }}
          >
            <div className="card-body">
              <i className="ri-phone-line"></i>
              <div className="lead">Total Contacts</div>
              <h2 className="card-title">{totalContacts}</h2>
              {/* <p className="small ">
                Oct 1 - Dec 31,<i className="ri-global-line"></i> Worldwide
              </p> */}
            </div>
          </div>
          <div
            className="card"
            style={{ width: "18rem", background: "#072E33", color: "#ffffff" }}
          >
            <div className="card-body">
              <div className="lead">Total Custom Fields</div>
              <h2 className="card-title">XX</h2>
              {/* <p className="small ">
                Oct 1 - Dec 31,<i className="ri-global-line"></i> Worldwide
              </p> */}
            </div>
          </div>
          <div
            className="card"
            style={{ width: "18rem", background: "#0C7075", color: "#ffffff" }}
          >
            <div className="card-body">
              <div className="lead">Total Tags</div>
              <h2 className="card-title">XX</h2>
              {/* <p className="small">
                Oct 1 - Dec 31,<i className="ri-global-line"></i> Worldwide
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
