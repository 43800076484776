import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import { RingLoader } from "react-spinners";

const TwoFactorAuth = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [otp, setOtp] = useState("");
  const handleVerify = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post("/api/user/sendotp", { otp });
      // console.log(response);

      if (response.status === 200) {
        if (response.data && response.data.token && response.data.user) {
          const { token, user } = response.data;
          localStorage.setItem("token", token);
          localStorage.setItem("firstname", user.firstname || "");
          localStorage.setItem("lastname", user.lastname || "");
          localStorage.setItem("email", user.email || "");
          toast.success("Verification successful [User Logged In]");
          navigate("/dashboard");
        } else {
          console.error("Invalid response format");
          toast.error("Invalid response format");
        }
      } else {
        console.error("Verification failed");
        toast.error("Verification failed");
      }
    } catch (error) {
      console.error("Error during verification:", error);
      toast.error("Error during verification");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container
        component="main"
        maxWidth="xs"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh", // 100% of the viewport height
        }}
      >
        <CssBaseline />
        {isLoading ? (
          // <div style={{ textAlign: "center" }}>
          //   <CircularProgress />
          //   <Typography variant="body2">Loading...</Typography>
          // </div>
          <div style={{ textAlign: "center" }}>
            <RingLoader
              color="#1976d2" // Customize the color
              loading={isLoading}
              css={css`
                display: block;
                margin: 0 auto;
              `}
              size={80} // Customize the size
            />
            <Typography variant="body2" style={{ marginTop: 1 }}>
              Loading...
            </Typography>
          </div>
        ) : (
          <Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Avatar
                sx={{ m: 1, bgcolor: "#25D366" }}
                style={{ marginTop: "6%" }}
              >
                <WhatsAppIcon />
              </Avatar>
            </Box>
            <Box
              sx={{
                marginTop: 2,
                marginBottom: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                padding: "30px",
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                style={{
                  color: "#1976d2",
                }}
              >
                Two-Factor Authentication
              </Typography>
              <Typography
                component="h6"
                variant="h6"
                style={{ color: "gray", fontSize: "14px", textAlign: "center" }}
              >
                A unique code has been sent to your email address Please check
                your inbox and enter the code below to complete the login
                process.
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="code"
                  label="Authentication Code"
                  name="code"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  autoComplete="code"
                  autoFocus
                />

                <Button
                  onClick={handleVerify}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Verify
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default TwoFactorAuth;
