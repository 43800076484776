import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ResetPasswordForm = ({ token }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    try {
      const response = await axios.post(
        `/api/user/ResetPassword/${token}`,

        {
          password: newPassword,
        }
      );
      setSuccessMessage(response.data.message);
      setErrorMessage("");
      setNewPassword("");
      setConfirmPassword("");
      toast.success("Password reset successful");
      navigate("/");
    } catch (error) {
      console.error(error);
      setErrorMessage("Failed to reset password");
      setSuccessMessage("");
      toast.error("Failed to reset password");
      alert("Failed to reset password");
    }
  };
  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Avatar
            sx={{ m: 1, bgcolor: "#25D366" }}
            style={{ marginTop: "12%" }}
          >
            <WhatsAppIcon />
          </Avatar>
        </Box>
        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            padding: "30px",
          }}
        >
          <Typography component="h1" variant="h6" style={{ color: "#1976d2" }}>
            Change Password?
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            style={{ color: "gray", fontSize: "14px" }}
          >
            change password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              id="password"
              label="New Password"
              name="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              autoComplete="password"
              autoFocus
            />
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              id="confirmpassword"
              label="Confirm Password"
              name="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="confirm-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
            </Button>
          </Box>
          {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPasswordForm;
