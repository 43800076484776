import axios from "axios";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const Activation = ({ entityType, entityId, isActive }) => {
  const [toggleState, setToggleState] = useState(isActive);

  useEffect(() => {
    setToggleState(isActive);
  }, [isActive]);

  const handleToggle = () => {
    const newState = !toggleState;
    setToggleState(newState);

    axios
      .put(`/api/template/activation/${entityType}/${entityId}`, {
        isActive: newState,
      })
      .then((response) => {
        Swal.fire("Updated!", "Activation status has been changed.", "success");

        
      })
      .catch((error) => {
        console.error("Error updating activation:", error);
        Swal.fire(
          "Error",
          "An error occurred while updating activation.",
          "error"
        );
      });
  };

  return (
    <div>
      <input
        type="checkbox"
        className="card-checkbox"
        checked={toggleState}
        onChange={handleToggle}
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          width: "20px",
          height: "20px",
          cursor: "pointer",
        }}
      />
    </div>
  );
};

export default Activation;
