import React, { useState, useEffect } from "react";
import { Timeline, Row, Col, Card, Table, Tag, Typography } from "antd";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import axios from "axios";
import { useParams } from "react-router-dom";
const { Text, Title } = Typography;

const MessageStatus = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { contactId } = useParams();

  const priority = {
    read: 3,
    delivered: 2,
    sent: 1,
    failed: 0,
  };

  const getTextCellRender = () => {
    return {
      render: (text) => <Text>{text}</Text>,
    };
  };

  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await axios.get(`/api/contact/getstatus/${contactId}`);
        if (response.data && response.data.messages) {
          const entries = response.data.messages.map((item) => {
            const entry = {};
            const datetime = new Date(Date.parse(item.createdAt));
            entry["timestamp"] = datetime.valueOf();
            entry["time"] = datetime.toLocaleString("sv");
            entry["from"] = item.metadata?.display_phone_number || "";
            entry["to"] = item.statuses[0]?.recipient_id || "";
            entry["type"] = item.statuses[0]?.conversation?.origin?.type || "";
            entry["status"] = "failed";
            entry["timeline"] = {};
            item.statuses.forEach((status) => {
              if (priority[entry["status"]] < priority[status.status]) {
                entry["status"] = status.status;
              }
              entry.timeline[status.status] = status.timestamp;
            });
            entry["waId"] = item.statuses[0]?.id || "";
            entry["sysId"] = item._id;
            entry["key"] = item._id;
            entry["errors"] = item.statuses[0]?.errors || [];
            return entry;
          });

          entries.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
          setData(entries);
        } else {
          setError("This Contact's Status is not Found in WABA Reports");
        }
      } catch (error) {
        console.error("Error fetching contact details:", error);
        setError("Error fetching contact details");
      } finally {
        setLoading(false);
      }
    };

    fetchContactDetails();
  }, [contactId]);
  const parseDateTime = (timestamp) => {
    if (timestamp)
      return new Date(Number(timestamp) * 1000).toLocaleString("sv");
    else return "————";
  };

  const columns = [
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Creation Time",
      dataIndex: "time",
      key: "time",
      sorter: (a, b) => {
        return Number(a.timestamp) - Number(b.timestamp);
      },
    },
    {
      title: "Last Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color;
        let x = status[0].toUpperCase() + status.slice(1);
        switch (x) {
          case "Delivered":
            color = "orange";
            break;
          case "Read":
            color = "green";
            break;
          case "Sent":
            color = "blue";
            break;
          case "Failed":
            color = "red";
            break;
          default:
            color = "gray";
        }
        return <Tag color={color}>{x}</Tag>;
      },
    },
    {
      title: "Whatsapp ID",
      dataIndex: "waId",
      key: "waId",
      // className: "waIdColumn",
      ...getTextCellRender(),
    },
  ];

  const expandedRowRender = (record) => {
    const renderTimeline = () => {
      const timelineItems = [];

      if (record.status.toLowerCase() === "read") {
        timelineItems.push(
          {
            children: "Accepted",
            dot: <SyncOutlined />,
            color: "black",
            label: (
              <span style={{ float: "right" }}>
                {parseDateTime(record.timeline.sent)}
              </span>
            ),
          },
          {
            children: "Sent",
            dot: <CheckCircleOutlined />,
            label: (
              <span style={{ float: "right" }}>
                {parseDateTime(record.timeline.sent)}
              </span>
            ),
          },
          {
            children: "Delivered",
            dot: <CheckCircleOutlined />,
            color: "orange",
            label: (
              <span style={{ float: "right" }}>
                {parseDateTime(record.timeline.delivered)}
              </span>
            ),
          },
          {
            children: "Read",
            dot: <CheckCircleOutlined />,
            color: "green",
            label: (
              <span style={{ float: "right" }}>
                {parseDateTime(record.timeline.read)}
              </span>
            ),
          }
        );
      } else if (record.status.toLowerCase() === "delivered") {
        timelineItems.push(
          {
            children: "Accepted",
            dot: <SyncOutlined />,
            color: "black",
            label: (
              <span style={{ float: "right" }}>
                {parseDateTime(record.timeline.sent)}
              </span>
            ),
          },
          {
            children: "Sent",
            dot: <CheckCircleOutlined />,
            label: (
              <span style={{ float: "right" }}>
                {parseDateTime(record.timeline.sent)}
              </span>
            ),
          },
          {
            children: "Delivered",
            dot: <CheckCircleOutlined />,
            color: "orange",
            label: (
              <span style={{ float: "right" }}>
                {parseDateTime(record.timeline.delivered)}
              </span>
            ),
          }
        );
      } else if (record.status.toLowerCase() === "sent") {
        timelineItems.push(
          {
            children: "Accepted",
            dot: <SyncOutlined />,
            color: "black",
            label: (
              <span style={{ float: "right" }}>
                {parseDateTime(record.timeline.sent)}
              </span>
            ),
          },
          {
            children: "Sent",
            dot: <CheckCircleOutlined />,
            label: (
              <span style={{ float: "right" }}>
                {parseDateTime(record.timeline.sent)}
              </span>
            ),
          }
        );
      } else if (record.status.toLowerCase() === "failed") {
        return record.errors.map((error, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <p>
              <strong>Error Code:</strong> {error.code || "N/A"}
            </p>
            <p>
              <strong>Error Message:</strong> {error.title || "N/A"}
            </p>
            <p>
              <strong>Error Data:</strong> {error.error_data?.details || "N/A"}
            </p>
          </div>
        ));
      }

      return <Timeline mode="left" items={timelineItems} />;
    };

    return (
      <Row>
        <Col span={10} offset={2}>
          <Card hoverable>{renderTimeline()}</Card>
        </Col>
        <Col span={8} offset={2}>
          <Card title="Preview Message" hoverable>
            {/* Render the preview message content here */}
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <div className="page-content w-100">
      <div className="row">
        <div className="col-xxl-12">
          <div id="contactList" className="card">
            <div className="card-body">
              {error && <div className="alert alert-danger">{error}</div>}
              <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                scroll={{ y: data.length > 10 ? 500 : null }}
                expandable={{ expandedRowRender }}
                // style={{ overflow: "auto" }}
                components={{
                  header: {
                    cell: ({ style, ...restProps }) => (
                      <th
                        {...restProps}
                        style={{
                          ...style,
                          backgroundColor: "#c6c6c6",
                          color: "black",
                          textAlign: "center",
                        }}
                      />
                    ),
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageStatus;
